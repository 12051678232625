import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-c55b11ee"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "actions" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_contacts_columns_form = _resolveComponent("contacts-columns-form");
    const _component_or_button = _resolveComponent("or-button");
    const _component_or_modal = _resolveComponent("or-modal");
    return (_openBlock(), _createBlock(_component_or_modal, {
        "data-test": "table-settings-modal",
        "title-text": _ctx.$t('contacts.tableSettingsModal.title'),
        "is-open": _ctx.isOpen,
        size: "small"
    }, {
        footer: _withCtx(({ close }) => [
            _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_or_button, {
                    "data-test": "cancel-table-settings-button",
                    type: "outline",
                    content: _ctx.$t('common.cancel'),
                    onClick: close
                }, null, 8, ["content", "onClick"]),
                _createVNode(_component_or_button, {
                    "data-test": "apply-table-settings-button",
                    content: _ctx.$t('common.apply'),
                    "is-disabled": !_ctx.isChanged,
                    onClick: _ctx.applyChanges
                }, null, 8, ["content", "is-disabled", "onClick"])
            ])
        ]),
        default: _withCtx(() => [
            _createVNode(_component_contacts_columns_form, {
                modelValue: _ctx.arrayOfColumns,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.arrayOfColumns) = $event)),
                title: _ctx.$t('contacts.tableSettingsModal.showColumns')
            }, null, 8, ["modelValue", "title"])
        ]),
        _: 1
    }, 8, ["title-text", "is-open"]));
}
