import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-78efa298"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    class: "contacts-table",
    "data-test": "contacts-table"
};
const _hoisted_2 = { class: "contacts-table-head" };
const _hoisted_3 = { class: "row head-row" };
const _hoisted_4 = { class: "header-cell action-select" };
const _hoisted_5 = { class: "contacts-table-body" };
const _hoisted_6 = ["onClick"];
const _hoisted_7 = {
    key: 0,
    class: "loading"
};
const _hoisted_8 = {
    key: 1,
    class: "empty",
    "data-test": "table-empty"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_or_checkbox = _resolveComponent("or-checkbox");
    const _component_contacts_table_column_header_cell = _resolveComponent("contacts-table-column-header-cell");
    const _component_contacts_table_data_cell = _resolveComponent("contacts-table-data-cell");
    const _component_or_loader = _resolveComponent("or-loader");
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["contacts-table-container", { 'full-height': _ctx.isLoading }])
    }, [
        _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                        _createVNode(_component_or_checkbox, {
                            "with-state": "",
                            "model-value": _ctx.mainCheckboxState,
                            "is-disabled": _ctx.isEmpty,
                            "onUpdate:modelValue": _ctx.handleSelectAll
                        }, null, 8, ["model-value", "is-disabled", "onUpdate:modelValue"])
                    ]),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderedColumns, (column) => {
                        return (_openBlock(), _createElementBlock("div", {
                            key: column.label,
                            class: "header-cell",
                            "data-test": "header-cell",
                            style: _normalizeStyle(_ctx.dataCellCss[column.key])
                        }, [
                            _createVNode(_component_contacts_table_column_header_cell, {
                                "column-key": column.key,
                                value: column.label,
                                width: _ctx.storedColumnsInfo[column.key].width,
                                "can-be-resized-now": !_ctx.isAnyResizing,
                                "can-be-ordered": _ctx.typesToOrder.includes(column.typeOf),
                                "order-mode": _ctx.columnToOrderBy === column.key ? _ctx.orderMode : null,
                                onWidthChange: _ctx.onColumnHeaderWidthChange,
                                onBarDragStart: _ctx.onColumnHeaderWidthChangeStart,
                                onBarDragEnd: _ctx.onColumnHeaderWidthChangeEnd,
                                onOrder: ($event) => (_ctx.onOrderChange(column.key, $event))
                            }, null, 8, ["column-key", "value", "width", "can-be-resized-now", "can-be-ordered", "order-mode", "onWidthChange", "onBarDragStart", "onBarDragEnd", "onOrder"])
                        ], 4));
                    }), 128))
                ])
            ]),
            _createElementVNode("div", _hoisted_5, [
                (!_ctx.isLoading && !_ctx.isEmpty)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.items, (contact) => {
                        return (_openBlock(), _createElementBlock("div", {
                            key: contact.contactId,
                            class: _normalizeClass(["row contact-row", {
                                    'selected': _ctx.selected.includes(contact.contactId)
                                }]),
                            onClick: ($event) => (_ctx.$emit('view', contact.contactId))
                        }, [
                            _createElementVNode("div", {
                                class: "header-cell select-action",
                                onClick: _cache[0] || (_cache[0] = _withModifiers(() => { }, ["stop"]))
                            }, [
                                _createVNode(_component_or_checkbox, {
                                    "model-value": _ctx.selected.includes(contact.contactId),
                                    "onUpdate:modelValue": ($event) => (_ctx.handleSelect(contact.contactId))
                                }, null, 8, ["model-value", "onUpdate:modelValue"])
                            ]),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderedColumns, (column) => {
                                return (_openBlock(), _createElementBlock("div", {
                                    key: column.key,
                                    class: _normalizeClass(["cell", { resizing: _ctx.resizingColumnKey === column.key }]),
                                    style: _normalizeStyle(_ctx.dataCellCss[column.key])
                                }, [
                                    _createVNode(_component_contacts_table_data_cell, {
                                        type: column.typeOf,
                                        contact: contact,
                                        "column-key": column.key
                                    }, null, 8, ["type", "contact", "column-key"])
                                ], 6));
                            }), 128))
                        ], 10, _hoisted_6));
                    }), 128))
                    : _createCommentVNode("", true)
            ])
        ]),
        (_ctx.isLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode(_component_or_loader)
            ]))
            : (_ctx.isEmpty)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.emptyTableText), 1))
                : _createCommentVNode("", true)
    ], 2));
}
