import _ from 'lodash';
import { computed, defineComponent, toRaw } from 'vue';
import { getEmptyContactFormValuesV2, isPropertyEmpty } from '@/utils';
import { ContactFormFieldV2 } from './ContactFormField';
export default defineComponent({
    name: 'ContactsForm',
    components: {
        ContactFormField: ContactFormFieldV2,
    },
    props: {
        fieldSchemas: {
            type: Array,
            required: true,
        },
        defaultValue: {
            type: Object,
            required: false,
            default: () => { },
        },
    },
    setup(props) {
        const cleanDefaultValue = computed(() => {
            return _.filter(props.defaultValue, field => !isPropertyEmpty(field.value)).map(toRaw);
        });
        return {
            cleanDefaultValue,
        };
    },
    data(props) {
        const emptyValues = getEmptyContactFormValuesV2(props.fieldSchemas);
        const clonedValues = _.cloneDeep(props.defaultValue);
        return {
            localValues: _.map(emptyValues, value => {
                const clonedValue = _.find(clonedValues, cloned => value.schemaId === cloned.schemaId);
                if (clonedValue && clonedValue.id) {
                    if (isPropertyEmpty(clonedValue.value))
                        clonedValue.value = value.value;
                    return clonedValue;
                }
                else {
                    return value;
                }
            }),
        };
    },
    computed: {
        cleanLocalValue() {
            return _.filter(this.localValues, field => !isPropertyEmpty(field.value)).map(toRaw);
        },
        isChanged() {
            return this.cleanDefaultValue.length !== this.cleanLocalValue.length
                || _.differenceWith(this.cleanLocalValue, this.cleanDefaultValue, _.isEqual).length > 0;
        },
    },
    methods: {
        submit() {
            this.$emit('submit', this.localValues);
        },
        handleUpdate(key, value) {
            const fieldToUpdate = _.find(this.localValues, value => value.schemaId === key);
            if (fieldToUpdate) {
                fieldToUpdate.value = value;
            }
            else {
                this.localValues.push({
                    schemaId: key,
                    value,
                });
            }
        },
    },
});
