import { defineComponent } from 'vue';
import { OrAvatar } from '@onereach/ui-components';
export default defineComponent({
    name: 'ContactsTableCompositeDataCellProfileInfo',
    components: {
        OrAvatar,
    },
    props: {
        contact: {
            type: Object,
            required: true,
        },
    },
    computed: {
        profileImage() {
            var _a, _b;
            return (_b = (_a = this.contact) === null || _a === void 0 ? void 0 : _a.profileImage) !== null && _b !== void 0 ? _b : '';
        },
        firstName() {
            var _a, _b;
            return (_b = (_a = this.contact) === null || _a === void 0 ? void 0 : _a.firstName) !== null && _b !== void 0 ? _b : '';
        },
        lastName() {
            var _a, _b;
            return (_b = (_a = this.contact) === null || _a === void 0 ? void 0 : _a.lastName) !== null && _b !== void 0 ? _b : '';
        },
        hasName() {
            return !!this.firstName || !!this.lastName;
        },
        name() {
            return this.hasName ? `${this.firstName} ${this.lastName}` : this.$t('contacts.noName');
        },
    },
});
