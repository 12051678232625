import { OrButtonV2 as OrButton, OrIconButton, OrCollapse, OrSelect, OrInput } from '@onereach/ui-components';
import useVuelidate from '@vuelidate/core';
import { minLength, required } from '@vuelidate/validators';
import { get, useToggle } from '@vueuse/core';
import _ from 'lodash';
import { defineComponent, watch, computed } from 'vue-demi';
import { SchemaItemTypes, SpecialCharactersRegexp } from '@/constants';
import { useLocalState } from '@/use';
import { alreadyExists, forbiddenChar } from '@/utils/validators';
import ContactBooksSchemaEditorItemProperties from './ContactBooksSchemaEditorItemProperties.vue';
import ContactBooksSchemaEditorItemTriggers from './ContactBooksSchemaEditorItemTriggers.vue';
export default defineComponent({
    name: 'ContactBooksSchemaEditorItem',
    components: {
        OrButton,
        OrIconButton,
        OrInput,
        OrCollapse,
        OrSelect,
        ContactBooksSchemaEditorItemProperties,
        ContactBooksSchemaEditorItemTriggers,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
        initTriggers: {
            type: Array,
            required: true,
        },
        isDeletable: {
            type: Boolean,
            required: false,
            default: true,
        },
        isDraggable: {
            type: Boolean,
            required: false,
            default: false,
        },
        undeletableProperties: {
            type: Array,
            required: false,
            default: () => [],
        },
        allItems: {
            type: Array,
            required: false,
            default: () => [],
        },
        editorItems: {
            type: Set,
            required: false,
            default: () => new Set(),
        },
    },
    emits: ['delete', 'update'],
    setup(props) {
        const [isOpen, toggleOpen] = useToggle(false);
        const { localState, isChanged, reset, replace } = useLocalState(props.item);
        const showSingularLabel = computed(() => {
            return [
                SchemaItemTypes.ARRAY_OF_IDENTIFIERS,
                SchemaItemTypes.ARRAY_OF_OBJECTS,
            ].includes(get(localState.typeOf));
        });
        watch(() => props.item, (item) => replace(get(item)), { deep: true });
        const v$ = useVuelidate({ $scope: 'ContactBooksSchemaEditor' });
        return {
            v$,
            localState,
            isChanged,
            showSingularLabel,
            reset,
            isOpen,
            toggleOpen,
        };
    },
    data() {
        return {
            schemaItemTypes: _.map(SchemaItemTypes, (item) => ({
                value: item,
                label: item,
            })),
        };
    },
    computed: {
        showProperties() {
            return this.localState.typeOf === SchemaItemTypes.ARRAY_OF_OBJECTS;
        },
        showTriggers() {
            return [
                SchemaItemTypes.ARRAY_OF_IDENTIFIERS,
                SchemaItemTypes.IDENTIFIER_OBJECT,
            ].includes(this.localState.typeOf);
        },
    },
    watch: {
        localState: {
            handler() {
                this.update();
            },
            deep: true,
        },
    },
    mounted() {
        this.editorItems.add(this);
    },
    beforeUnmount() {
        this.editorItems.delete(this);
    },
    validations() {
        const other = computed(() => {
            const indexToRemove = this.allItems.indexOf(this.item.key);
            const otherItems = _.clone(this.allItems);
            otherItems.splice(indexToRemove, 1);
            return otherItems;
        });
        return {
            localState: {
                key: {
                    required,
                    minLengthValue: minLength(2),
                    notSpecialChar: forbiddenChar(SpecialCharactersRegexp, this.$t('contactBooks.schemaEditor.columnNameCharNotAllowed')),
                    alreadyExists: alreadyExists(other, this.$t('contactBooks.schemaEditor.columnAlreadyExists')),
                },
                label: {
                    required,
                    notDot: forbiddenChar(/\./, this.$t('contactBooks.schemaEditor.labelDotNotAllowed')),
                    minLengthValue: minLength(2),
                },
                ...(this.showSingularLabel && {
                    singularLabel: {
                        required,
                        notDot: forbiddenChar(/\./, this.$t('contactBooks.schemaEditor.labelDotNotAllowed')),
                        minLengthValue: minLength(2),
                    },
                }),
            },
        };
    },
    methods: {
        handleVisibilityFlip() {
            this.localState.visibility = !this.localState.visibility;
            this.update();
        },
        handleApply() {
            this.update();
        },
        update() {
            this.$emit('update', this.localState);
        },
    },
});
