import { OrCheckbox, OrLoaderV3 as OrLoader } from '@onereach/ui-components';
import _ from 'lodash';
import { storeToRefs } from 'pinia';
import { defineComponent } from 'vue';
import { ContactBooksOrderModes, SchemaItemTypes } from '@/constants';
import { useContactsV1 as useContacts } from '@/stores';
import { useContactsTableStoredValue } from '@/use';
import { convertSchemaToOrderedArray, immutableSplice, getDefaultContactsTableStoredValue } from '@/utils';
import ContactsTableColumnHeaderCell from './ContactsTableColumnHeaderCell.vue';
import ContactsTableDataCell from './ContactsTableDataCell';
const typesToOrder = [
    SchemaItemTypes.STRING,
    SchemaItemTypes.MD,
    SchemaItemTypes.INTEGER,
    SchemaItemTypes.DOUBLE,
    SchemaItemTypes.DATE,
    SchemaItemTypes.ARRAY_OF_STRINGS,
    undefined,
    null,
];
export default defineComponent({
    name: 'ContactsTable',
    components: {
        OrLoader,
        OrCheckbox,
        ContactsTableDataCell,
        ContactsTableColumnHeaderCell,
    },
    props: {
        bookName: {
            type: String,
            required: true,
        },
        items: {
            type: Array,
            required: false,
            default: () => [],
        },
        schema: {
            type: Object,
            required: true,
        },
        selected: {
            type: Array,
            required: false,
            default: () => [],
        },
        columnToOrderBy: {
            type: String,
            required: false,
            default: 'created',
        },
        orderMode: {
            type: String,
            required: false,
            default: ContactBooksOrderModes.DESC,
        },
        isSearching: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    emits: ['select', 'view', 'order'],
    setup(props) {
        const contactsTableStoredValue = useContactsTableStoredValue(props.bookName, props.schema);
        const { isLoading } = storeToRefs(useContacts());
        return {
            typesToOrder,
            contactsTableStoredValue,
            isLoading,
        };
    },
    data() {
        return {
            resizingColumnKey: null,
        };
    },
    computed: {
        storedColumnsInfo() {
            return this.contactsTableStoredValue.columns;
        },
        dataCellCss() {
            return _.mapValues(this.storedColumnsInfo, ({ width }) => ({
                width: width + 'px',
            }));
        },
        isEmpty() {
            return this.items.length === 0;
        },
        orderedColumns() {
            return convertSchemaToOrderedArray(this.schema, true)
                .filter(({ key }) => this.storedColumnsInfo[key].isShown);
        },
        allItemsIds() {
            return this.items.map(({ contactId }) => contactId);
        },
        isAnySelected() {
            return this.selected.length > 0;
        },
        isAllSelected() {
            return !this.isEmpty && _.difference(this.allItemsIds, this.selected).length === 0;
        },
        mainCheckboxState() {
            if (this.isAllSelected) {
                return {
                    icon: 'done',
                };
            }
            if (this.isAnySelected) {
                return {
                    icon: 'add',
                };
            }
            return {
                icon: '',
            };
        },
        isAnyResizing() {
            return this.resizingColumnKey !== null;
        },
        emptyTableText() {
            return this.isSearching ? this.$t('contacts.table.noMatchingRecords') : this.$t('contacts.table.empty');
        },
    },
    watch: {
        schema(val) {
            const defaultStoredValue = getDefaultContactsTableStoredValue(val);
            _.defaults(this.contactsTableStoredValue.columns, defaultStoredValue.columns);
        },
    },
    methods: {
        handleSelectAll() {
            this.$emit('select', this.isAllSelected ? [] : _.union(this.allItemsIds, this.selected));
        },
        handleSelect(contactId) {
            const index = this.selected.indexOf(contactId);
            if (index > -1) {
                this.$emit('select', immutableSplice(this.selected, index, 1));
            }
            else {
                this.$emit('select', [...this.selected, contactId]);
            }
        },
        onColumnHeaderWidthChangeStart(columnKey) {
            this.resizingColumnKey = columnKey;
        },
        onColumnHeaderWidthChange(columnKey, newWidth) {
            this.storedColumnsInfo[columnKey].width = newWidth;
        },
        onColumnHeaderWidthChangeEnd() {
            this.resizingColumnKey = null;
        },
        onOrderChange(columnKey, newOrderMode) {
            this.$emit('order', columnKey, newOrderMode);
        },
    },
});
