import { OrTabsV3 as OrTabs, useResponsive } from '@onereach/ui-components';
import { computed, defineComponent } from 'vue';
import { useRoute } from 'vue-router';
export default defineComponent({
    name: 'RoutingTabsContainer',
    components: {
        OrTabs,
    },
    setup() {
        const route = useRoute();
        const currentRouteName = computed(() => route.name);
        const isCustomFullPage = computed(() => ['all contacts v2', 'contacts v2'].includes(route.name));
        const { isMobile } = useResponsive();
        return {
            currentRouteName,
            isCustomFullPage,
            isMobile,
        };
    },
    data() {
        return {
            selectedTab: '',
        };
    },
    computed: {
        tabsItems() {
            return [
                {
                    value: 'books v2 index',
                    label: this.$t('tabsItems.books'),
                },
                {
                    value: 'all contacts v2',
                    label: this.$t('tabsItems.contacts'),
                },
                {
                    value: 'field schemas',
                    label: this.$t('tabsItems.fields'),
                },
            ];
        },
        isAnyTabSelected() {
            return this.tabsItems.map(item => item.value).includes(this.currentRouteName);
        },
    },
    watch: {
        currentRouteName: {
            handler(val) {
                this.selectedTab = val;
            },
            immediate: true,
        },
        selectedTab(val) {
            if (this.$route.name === val)
                return;
            this.$router.replace({ name: val });
        },
    },
});
