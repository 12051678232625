import { ColumnTypes, } from '@onereach/types-contacts-api';
import { OrButtonV3 as OrButton, useResponsive } from '@onereach/ui-components';
import { onClickOutside, useMagicKeys } from '@vueuse/core';
import _ from 'lodash';
import { defineComponent, computed, ref, watch } from 'vue';
import OrConditionalSidebarModal from '../../OrConditionalSidebarModal.vue';
import { ContactsViewFieldV2 } from './ContactsViewField';
export default defineComponent({
    name: 'ContactViewModal',
    components: {
        OrConditionalSidebarModal,
        OrButton,
        ContactsViewField: ContactsViewFieldV2,
    },
    props: {
        isOpen: {
            type: Boolean,
            required: true,
        },
        isReadonly: {
            type: Boolean,
            required: false,
            default: false,
        },
        fieldSchemas: {
            type: Array,
            required: false,
            default: () => [],
        },
        contact: {
            type: Object,
            required: true,
        },
    },
    emits: ['edit', 'close'],
    setup(props, { emit }) {
        const rootRef = ref();
        const rootElement = computed(() => { var _a, _b; return (_b = (_a = rootRef.value) === null || _a === void 0 ? void 0 : _a.rootRef) === null || _b === void 0 ? void 0 : _b.root; });
        const { isMobile } = useResponsive();
        onClickOutside(rootElement, evt => {
            const elementsPath = evt.composedPath().map(t => t);
            if (elementsPath.map(el => el.tagName).includes('BUTTON') || elementsPath.map(el => el.className)
                .some(classes => ['or-toast-container', 'or-overlay', 'or-floating'].some(cls => classes === null || classes === void 0 ? void 0 : classes.includes(cls))))
                return;
            emit('close');
        });
        const { escape } = useMagicKeys();
        watch(escape, (val) => {
            if (val)
                emit('close');
        });
        return {
            rootRef,
            isMobile,
        };
    },
    computed: {
        extendedFieldSchemas() {
            return [
                {
                    id: 'id',
                    label: 'Id',
                    type: ColumnTypes.string,
                    machine_name: '_id',
                },
                ...this.fieldSchemas,
                {
                    id: 'created_at',
                    label: 'Created at',
                    type: ColumnTypes.date,
                    machine_name: '_created_at',
                },
                {
                    id: 'updated_at',
                    label: 'Updated at',
                    type: ColumnTypes.date,
                    machine_name: '_updated_at',
                },
            ];
        },
        contactObject() {
            return _.defaults({}, this.contact.fieldValues, {
                id: {
                    id: 'id',
                    value: this.contact.id,
                    schemaId: 'id',
                    machine_name: '_id',
                },
                created_at: {
                    id: 'created_at',
                    value: this.contact.created_at,
                    schemaId: 'created_at',
                    machine_name: '_created_at',
                },
                updated_at: {
                    id: 'updated_at',
                    value: this.contact.updated_at,
                    schemaId: 'updated_at',
                    machine_name: '_updated_at',
                },
            }, _.fromPairs(_.map(this.extendedFieldSchemas, field => [field.id, {
                    value: undefined,
                    schemaId: field.id,
                }])));
        },
    },
});
