import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3ec566be"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "actions" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_contact_book_settings_form = _resolveComponent("contact-book-settings-form");
    const _component_or_button = _resolveComponent("or-button");
    const _component_or_modal = _resolveComponent("or-modal");
    return (_openBlock(), _createBlock(_component_or_modal, {
        "title-text": _ctx.$t('contactBooks.settingsModal.title'),
        "is-open": _ctx.isOpen,
        size: "medium"
    }, {
        footer: _withCtx(({ close }) => [
            _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_or_button, {
                    type: "outline",
                    content: _ctx.$t('common.cancel'),
                    onClick: close
                }, null, 8, ["content", "onClick"]),
                _createVNode(_component_or_button, {
                    content: _ctx.$t('common.save'),
                    "is-disabled": _ctx.notChanged,
                    "is-loading": _ctx.isSubmitting,
                    onClick: _ctx.triggerSubmit
                }, null, 8, ["content", "is-disabled", "is-loading", "onClick"])
            ])
        ]),
        default: _withCtx(() => [
            _createVNode(_component_contact_book_settings_form, {
                ref: "bookSettingsForm",
                "error-reference": "bookSettingsForm",
                "book-name": _ctx.bookName,
                "field-schemas": _ctx.fieldSchemas,
                "all-field-schemas": _ctx.allFieldSchemas,
                "schema-presets": _ctx.schemaPresets,
                "book-schema-presets": _ctx.bookSchemaPresets,
                "init-triggers": _ctx.initTriggers,
                onSubmit: _ctx.handleSubmit
            }, null, 8, ["book-name", "field-schemas", "all-field-schemas", "schema-presets", "book-schema-presets", "init-triggers", "onSubmit"])
        ]),
        _: 1
    }, 8, ["title-text", "is-open"]));
}
