import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-702081d1"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    class: "add-contact-book-form",
    "data-test": "add-contact-book-form"
};
const _hoisted_2 = { class: "row" };
const _hoisted_3 = { class: "row" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _a, _b;
    const _component_or_input = _resolveComponent("or-input");
    const _component_ContactBooksSchemaEditor = _resolveComponent("ContactBooksSchemaEditor");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_or_input, {
                ref: "bookNameTextbox",
                modelValue: _ctx.book.name,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.book.name) = $event)),
                label: _ctx.$t('contactBooks.addForm.contactBookName'),
                "is-invalid": _ctx.v$.name.$error,
                error: (_a = _ctx.v$.name.$errors[0]) === null || _a === void 0 ? void 0 : _a.$message,
                onBlur: _ctx.onBookNameBlur
            }, null, 8, ["modelValue", "label", "is-invalid", "error", "onBlur"]),
            _createVNode(_component_or_input, {
                modelValue: _ctx.book.tableName,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.book.tableName) = $event)),
                "is-disabled": "",
                "is-invalid": _ctx.v$.tableName.$error,
                error: (_b = _ctx.v$.tableName.$errors[0]) === null || _b === void 0 ? void 0 : _b.$message,
                label: _ctx.$t('contactBooks.addForm.contactTableName')
            }, null, 8, ["modelValue", "is-invalid", "error", "label"])
        ]),
        _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_ContactBooksSchemaEditor, {
                ref: "schemaEditor",
                schema: _ctx.defaultSchema,
                "init-triggers": _ctx.initTriggers,
                "required-fields": _ctx.requiredFields,
                "required-fields-properties": _ctx.requiredFieldsProperties,
                "group-mapping": _ctx.groupMapping
            }, null, 8, ["schema", "init-triggers", "required-fields", "required-fields-properties", "group-mapping"])
        ])
    ]));
}
