import { defineComponent, toRef } from 'vue';
import { OrButtonV2 as OrButton, OrInput } from '@onereach/ui-components';
import ContactsFilterFormMatch from './ContactsFilterFormMatch.vue';
import ContactsFilterFormConditionItem from './ContactsFilterFormConditionItem.vue';
import _ from 'lodash';
import useVuelidate from '@vuelidate/core';
import { sameFilterNameAlreadyExists } from '@/utils/validators';
import { minLength, required } from '@vuelidate/validators';
import { getDefaultNewFilter, trimAll } from '@/utils';
export default defineComponent({
    name: 'ContactsFilterForm',
    components: {
        OrButton,
        OrInput,
        ContactsFilterFormMatch,
        ContactsFilterFormConditionItem,
    },
    props: {
        isLoading: {
            type: Boolean,
            required: false,
            default: false,
        },
        filter: {
            type: Object,
            required: false,
            default: () => getDefaultNewFilter(),
        },
        allFilters: {
            type: Array,
            required: false,
            default: () => [],
        },
        schema: {
            type: Object,
            required: false,
            default: () => null,
        },
    },
    setup() {
        return {
            v$: useVuelidate({ $scope: 'ContactsFilterEditor' }),
        };
    },
    data(props) {
        var _a;
        let lastKey = 0;
        const localValue = (_a = _.cloneDeep(props.filter)) !== null && _a !== void 0 ? _a : getDefaultNewFilter();
        const keysMap = new WeakMap();
        for (const condition of localValue.conditions) {
            keysMap.set(condition, lastKey++);
        }
        return {
            lastKey,
            keysMap,
            localValue,
            conditionItems: new Set(),
        };
    },
    computed: {
        isNameInvalid() {
            return this.v$.localValue.name.$error || this.v$.localValue.sameFilterName.$invalid;
        },
        nameErrorMessage() {
            var _a, _b;
            if (!this.isNameInvalid)
                return undefined;
            return (_b = (_a = this.v$.localValue.name.$errors[0]) === null || _a === void 0 ? void 0 : _a.$message) !== null && _b !== void 0 ? _b : this.v$.localValue.sameFilterName.$message;
        },
        isChanged() {
            return !_.isEqual(this.filter, this.localValue);
        },
        conditions() {
            var _a;
            return (_a = this.localValue.conditions) !== null && _a !== void 0 ? _a : [];
        },
    },
    watch: {
        filter(val) {
            this.localValue = _.cloneDeep(val);
            for (const condition of this.localValue.conditions) {
                this.keysMap.set(condition, this.lastKey++);
            }
        },
    },
    validations() {
        const allFiltersRef = toRef(this.$props, 'allFilters');
        return {
            localValue: {
                name: {
                    required,
                    minLengthValue: minLength(3),
                },
                sameFilterName: sameFilterNameAlreadyExists(allFiltersRef, this.$t('contacts.filterModal.sameFilterName')),
            },
        };
    },
    methods: {
        tryGetKey(condition) {
            var _a;
            return (_a = this.keysMap.get(condition)) !== null && _a !== void 0 ? _a : null;
        },
        getOutputFilter() {
            return _.cloneDeep(this.localValue);
        },
        onNameBlur() {
            this.localValue.name = trimAll(this.localValue.name);
            this.v$.localValue.$touch();
        },
        addCondition() {
            const condition = {};
            this.conditions.push(condition);
            this.keysMap.set(condition, this.lastKey++);
        },
        deleteConditionByIndex(index) {
            this.conditions.splice(index, 1);
        },
        scrollToFirstInvalidItem() {
            for (const item of this.conditionItems)
                if (item.v$.$invalid) {
                    item.$el.scrollIntoView({ behavior: 'smooth' });
                    return;
                }
        },
    },
});
