import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4879b1cc"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "migration-modal-loader-container flex flex-col items-center content-center justify-around h-full"
};
const _hoisted_2 = {
    key: 1,
    class: "migration-modal-content flex flex-col gap-md"
};
const _hoisted_3 = { class: "actions" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_or_loader = _resolveComponent("or-loader");
    const _component_or_input = _resolveComponent("or-input");
    const _component_contact_books_migration_form = _resolveComponent("contact-books-migration-form");
    const _component_or_button = _resolveComponent("or-button");
    const _component_or_modal = _resolveComponent("or-modal");
    return (_openBlock(), _createBlock(_component_or_modal, {
        class: "migration-modal",
        "title-text": _ctx.$t('contactBooks.migrationModal.title'),
        "is-open": _ctx.isOpen,
        size: "medium"
    }, {
        footer: _withCtx(({ close }) => [
            _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_or_button, {
                    type: "outline",
                    content: _ctx.$t('common.cancel'),
                    onClick: close
                }, null, 8, ["content", "onClick"]),
                (!_ctx.isLoading)
                    ? (_openBlock(), _createBlock(_component_or_button, {
                        key: 0,
                        "is-loading": _ctx.isPreloading,
                        "is-disabled": _ctx.isNothingSelected,
                        content: _ctx.$t('contactBooks.migrationModal.migrateButton'),
                        onClick: _ctx.handleMigrate
                    }, null, 8, ["is-loading", "is-disabled", "content", "onClick"]))
                    : _createCommentVNode("", true)
            ])
        ]),
        default: _withCtx(() => {
            var _a;
            return [
                (_ctx.isLoading)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        _createVNode(_component_or_loader, {
                            class: "w-full",
                            variant: "linear",
                            title: _ctx.$t('contactBooks.migrationModal.migrating'),
                            description: _ctx.$t('contactBooks.migrationModal.preparingData')
                        }, null, 8, ["title", "description"])
                    ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createVNode(_component_or_input, {
                            ref: "bookNameInputRef",
                            modelValue: _ctx.newBookName,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.newBookName) = $event)),
                            "data-test": "migration-modal-book-name-input",
                            label: _ctx.$t('contactBooks.migrationModal.newBookName'),
                            "is-invalid": _ctx.v$.newBookName.$error,
                            error: (_a = _ctx.v$.newBookName.$errors[0]) === null || _a === void 0 ? void 0 : _a.$message,
                            onBlur: _ctx.onBookNameBlur
                        }, null, 8, ["modelValue", "label", "is-invalid", "error", "onBlur"]),
                        _createVNode(_component_contact_books_migration_form, {
                            ref: "migrationFormRef",
                            modelValue: _ctx.arrayOfColumns,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.arrayOfColumns) = $event)),
                            "is-loading": _ctx.isPreloading,
                            schema: _ctx.schema,
                            "fields-v2": _ctx.fieldsV2,
                            title: _ctx.$t('contactBooks.migrationModal.fieldsToMigrate')
                        }, null, 8, ["modelValue", "is-loading", "schema", "fields-v2", "title"])
                    ]))
            ];
        }),
        _: 1
    }, 8, ["title-text", "is-open"]));
}
