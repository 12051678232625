import { OrButtonV2 as OrButton, OrModal } from '@onereach/ui-components';
import useVuelidate from '@vuelidate/core';
import { defineComponent, ref } from 'vue';
import ContactBookSettingsForm from './ContactBookSettingsForm.vue';
export default defineComponent({
    name: 'ContactBooksSettingsModal',
    components: {
        ContactBookSettingsForm,
        OrModal,
        OrButton,
    },
    props: {
        isOpen: {
            type: Boolean,
            required: true,
        },
        bookName: {
            type: String,
            required: false,
            default: '',
        },
        fieldSchemas: {
            type: Array,
            required: false,
            default: () => [],
        },
        allFieldSchemas: {
            type: Array,
            required: false,
            default: () => [],
        },
        initTriggers: {
            type: Array,
            required: true,
        },
        schemaPresets: {
            type: Array,
            required: false,
            default: () => [],
        },
        bookSchemaPresets: {
            type: Array,
            required: false,
            default: () => [],
        },
        isSubmitting: {
            type: Boolean,
            required: true,
        },
    },
    emits: ['update'],
    setup() {
        const bookSettingsForm = ref();
        const v$ = useVuelidate({ $scope: 'ContactBooksSchemaEditor' });
        return {
            bookSettingsForm,
            v$,
        };
    },
    computed: {
        notChanged() {
            return this.v$.$invalid && this.v$.$silentErrors[0].$validator === 'notChanged';
        },
    },
    methods: {
        async triggerSubmit() {
            var _a, _b;
            this.v$.$touch();
            if (await this.v$.$validate()) {
                (_a = this.bookSettingsForm) === null || _a === void 0 ? void 0 : _a.submit();
            }
            else {
                (_b = this.bookSettingsForm) === null || _b === void 0 ? void 0 : _b.scrollToFirstInvalidItem();
            }
        },
        handleSubmit(fieldSchemas, schemaPresets, bookLabel) {
            this.$emit('update', fieldSchemas, schemaPresets, bookLabel);
        },
    },
});
