import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7991e264"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "not-found" };
const _hoisted_2 = { class: "inner" };
const _hoisted_3 = { class: "status" };
const _hoisted_4 = { class: "title" };
const _hoisted_5 = { class: "text" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_or_button = _resolveComponent("or-button");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.code, (char, index) => {
                    return (_openBlock(), _createElementBlock("span", { key: index }, _toDisplayString(char), 1));
                }), 128))
            ]),
            _createElementVNode("h4", _hoisted_4, _toDisplayString(_ctx.$t('errors.notFound.title')), 1),
            _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('errors.notFound.text')), 1),
            _createVNode(_component_or_button, {
                content: _ctx.$t('errors.notFound.goBack'),
                onClick: _ctx.$router.back
            }, null, 8, ["content", "onClick"])
        ])
    ]));
}
