import { OrModalV3 as OrModal, SidebarPlacement, OrSidebarV3 as OrSidebar, OrTeleportV3 as OrTeleport, useResponsive, } from '@onereach/ui-components';
import { defineComponent, ref } from 'vue';
export default defineComponent({
    name: 'OrConditionalSidebarModal',
    components: {
        OrModal,
        OrSidebar,
        OrTeleport,
    },
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        width: {
            type: Number,
            default: 400,
        },
        draggable: {
            type: Boolean,
            default: false,
        },
        collapsible: {
            type: Boolean,
            default: false,
        },
        side: {
            type: String,
            default: SidebarPlacement.Right,
        },
        isAbsolutePositioned: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['open', 'close'],
    setup() {
        const { isMobile } = useResponsive();
        const rootRef = ref();
        return {
            isMobile,
            rootRef,
        };
    },
    computed: {
        sidebarStyles() {
            const xPlacement = this.side === SidebarPlacement.Right ? 'right-none' : 'left-none';
            return this.isAbsolutePositioned
                ? `!absolute inset-y-none ${xPlacement} z-[5] shadow-modal dark:shadow-modal-dark`
                : '!relative';
        },
    },
    watch: {
        isOpen(val) {
            var _a, _b;
            (_b = (_a = this === null || this === void 0 ? void 0 : this.rootRef) === null || _a === void 0 ? void 0 : _a[val ? 'open' : 'close']) === null || _b === void 0 ? void 0 : _b.call(_a);
        },
    },
});
