import { OrChips, OrDateTimePicker, OrIconButton, OrSelect, OrInput } from '@onereach/ui-components';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useVModel } from '@vueuse/core';
import _ from 'lodash';
import { defineComponent } from 'vue';
import { SchemaItemTypes } from '@/constants';
import { getFilterOperatorOptions, trimAll } from '@/utils';
const SUPPORTED_TYPES = [
    SchemaItemTypes.ARRAY_OF_OBJECTS,
    SchemaItemTypes.ARRAY_OF_STRINGS,
    SchemaItemTypes.ARRAY_OF_IDENTIFIERS,
    SchemaItemTypes.STRING,
    SchemaItemTypes.IDENTIFIER_OBJECT,
    SchemaItemTypes.INTEGER,
    SchemaItemTypes.DOUBLE,
    SchemaItemTypes.DATE,
];
export default defineComponent({
    name: 'ContactsFilterFormConditionItem',
    components: {
        OrIconButton,
        OrChips,
        OrSelect,
        OrInput,
        OrDateTimePicker,
    },
    props: {
        modelValue: {
            type: Object,
            required: false,
            default: () => { },
        },
        schema: {
            type: Object,
            required: true,
        },
        formItems: {
            type: Set,
            required: false,
            default: () => new Set(),
        },
        isLoading: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    emits: ['update:modelValue', 'delete'],
    setup(props, { emit }) {
        const conditionModel = useVModel(props, 'modelValue', emit, { deep: true });
        return {
            v$: useVuelidate({ $scope: 'ContactsFilterEditor' }),
            conditionModel,
        };
    },
    computed: {
        dateTimeValue: {
            get() {
                return this.conditionModel.value ? new Date(this.conditionModel.value) : undefined;
            },
            set(val) {
                this.conditionModel.value = val === null || val === void 0 ? void 0 : val.toISOString();
            },
        },
        schemaOptions() {
            return _.entries(this.schema).filter(([, item]) => SUPPORTED_TYPES.includes(item.typeOf))
                .flatMap(([name, item]) => (item.properties && !_.isEmpty(item.properties)) ? _.keys(item.properties).map(propertyName => ({
                value: `${name}.${propertyName}`,
                label: `${name}.${propertyName}`,
            })) : ({
                value: name,
                label: item.label,
            }))
                .sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 :
                b.label.toLowerCase() > a.label.toLowerCase() ? -1 : 0);
        },
        operatorOptions() {
            if (!this.schemaItem)
                return getFilterOperatorOptions(SchemaItemTypes.STRING, this.$t);
            return getFilterOperatorOptions(this.schemaItem.typeOf, this.$t);
        },
        schemaItem() {
            var _a;
            if (!((_a = this.conditionModel) === null || _a === void 0 ? void 0 : _a.property))
                return null;
            return this.schema[this.conditionModel.property];
        },
        isArray() {
            return false; //TODO: currently arrays are not fully supported
            /*
            if (!this.schemaItem) return false;
            return this.schemaItem.typeOf.startsWith('array of');
             */
        },
        valueLabel() {
            var _a;
            return this.isArray ?
                (_a = this.schemaItem) === null || _a === void 0 ? void 0 : _a.label :
                this.$t('contacts.filterModal.conditions.value');
        },
    },
    watch: {
        'conditionModel.property'() {
            var _a;
            this.conditionModel.operator = '';
            this.conditionModel.value = this.isArray ? [] : '';
            switch ((_a = this.schema[this.conditionModel.property]) === null || _a === void 0 ? void 0 : _a.typeOf) {
                case SchemaItemTypes.DOUBLE:
                case SchemaItemTypes.INTEGER:
                    this.conditionModel.type = 'number';
                    break;
                case SchemaItemTypes.DATE:
                    this.conditionModel.type = 'date';
                    break;
                default:
                    this.conditionModel.type = 'string';
                    break;
            }
        },
    },
    validations() {
        return {
            conditionModel: {
                property: {
                    required,
                },
                operator: {
                    required,
                },
                value: {
                    required,
                },
            },
        };
    },
    mounted() {
        this.formItems.add(this);
    },
    beforeUnmount() {
        this.formItems.delete(this);
    },
    methods: {
        onBlur() {
            this.conditionModel.value = trimAll(this.conditionModel.value);
            this.v$.conditionModel.value.$touch();
        },
    },
});
