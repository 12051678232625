import { OrIconButton } from '@onereach/ui-components';
import { defineComponent } from 'vue';
import { ContactBooksViewModes } from '@/constants';
export default defineComponent({
    components: {
        OrIconButton,
    },
    props: {
        activeMode: {
            type: String,
            required: true,
        },
    },
    emits: ['select'],
    data() {
        return {
            ContactBooksViewModes,
        };
    },
});
