import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5c3077a2"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    class: "contact-book-schema-editor editor-container",
    "data-test": "contact-book-schema-editor"
};
const _hoisted_2 = { class: "header" };
const _hoisted_3 = { class: "title" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_or_switch = _resolveComponent("or-switch");
    const _component_contact_books_schema_editor_item = _resolveComponent("contact-books-schema-editor-item");
    const _component_contact_books_schema_editor_item_list_grouped = _resolveComponent("contact-books-schema-editor-item-list-grouped");
    const _component_draggable = _resolveComponent("draggable");
    const _component_or_button = _resolveComponent("or-button");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('contactBooks.schemaEditor.columns')), 1),
            (_ctx.isGroupViewAvailable)
                ? (_openBlock(), _createBlock(_component_or_switch, {
                    key: 0,
                    modelValue: _ctx.isGroupedView,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.isGroupedView) = $event)),
                    "data-test": "show-grouped-switch",
                    label: _ctx.$t('contactBooks.schemaEditor.showGrouped')
                }, null, 8, ["modelValue", "label"]))
                : _createCommentVNode("", true)
        ]),
        (_ctx.isGroupedView)
            ? (_openBlock(), _createBlock(_component_contact_books_schema_editor_item_list_grouped, {
                key: 0,
                ref: "groupedList",
                items: _ctx.items,
                "group-mapping": _ctx.groupMapping,
                onAddColumn: _ctx.handleAddColumn
            }, {
                item: _withCtx(({ element, index }) => [
                    _createVNode(_component_contact_books_schema_editor_item, {
                        item: element,
                        "editor-items": _ctx.editorItems,
                        "all-items": _ctx.allItemsNames,
                        "is-deletable": !_ctx.requiredFields.includes(element.key),
                        "undeletable-properties": _ctx.requiredFieldsProperties[element.key],
                        "init-triggers": _ctx.initTriggers,
                        onDelete: ($event) => (_ctx.handleDelete(index)),
                        onUpdate: ($event) => (_ctx.handleUpdate(index, $event))
                    }, null, 8, ["item", "editor-items", "all-items", "is-deletable", "undeletable-properties", "init-triggers", "onDelete", "onUpdate"])
                ]),
                _: 1
            }, 8, ["items", "group-mapping", "onAddColumn"]))
            : (_openBlock(), _createBlock(_component_draggable, {
                key: 1,
                modelValue: _ctx.items,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.items) = $event)),
                handle: ".dragger",
                class: "items-list",
                "data-test": "contact-book-columns-list",
                "item-key": _ctx.getOrCreateTemporaryKey
            }, {
                item: _withCtx(({ element, index }) => [
                    _createVNode(_component_contact_books_schema_editor_item, {
                        item: element,
                        "editor-items": _ctx.editorItems,
                        "all-items": _ctx.allItemsNames,
                        "is-deletable": !_ctx.requiredFields.includes(element.key),
                        "undeletable-properties": _ctx.requiredFieldsProperties[element.key],
                        "is-draggable": true,
                        "init-triggers": _ctx.initTriggers,
                        onDelete: ($event) => (_ctx.handleDelete(index)),
                        onUpdate: ($event) => (_ctx.handleUpdate(index, $event))
                    }, null, 8, ["item", "editor-items", "all-items", "is-deletable", "undeletable-properties", "init-triggers", "onDelete", "onUpdate"])
                ]),
                _: 1
            }, 8, ["modelValue", "item-key"])),
        (!_ctx.isGroupedView)
            ? (_openBlock(), _createBlock(_component_or_button, {
                key: 2,
                type: "text",
                icon: "add",
                "icon-position": "left",
                content: _ctx.$t('contactBooks.schemaEditor.addColumn'),
                onClick: _ctx.handleAddColumn
            }, null, 8, ["content", "onClick"]))
            : _createCommentVNode("", true)
    ]));
}
