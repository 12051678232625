import { OrIconButton, OrOverflowMenu } from '@onereach/ui-components';
import { defineComponent } from 'vue';
import config from '@/config';
export default defineComponent({
    name: 'ContactBookOverflowMenu',
    components: {
        OrIconButton,
        OrOverflowMenu,
    },
    props: {
        isV2: {
            type: Boolean,
            required: false,
            default: false,
        },
        isSharedBook: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    emits: ['update', 'delete', 'migrate', 'open'],
    computed: {
        menuItems() {
            return [
                {
                    icon: 'open_in_new',
                    label: this.$t('contactBooks.controls.openInNewTab'),
                    handler: () => this.$emit('open'),
                },
                ...(config.CONTACTS_API_ENABLED && !this.isV2 && !this.isSharedBook ? [
                    {
                        icon: 'publish',
                        label: this.$t('contactBooks.controls.migrate'),
                        handler: () => this.$emit('migrate'),
                    },
                ] : []),
                {
                    icon: 'delete',
                    label: this.$t('common.delete'),
                    handler: () => this.$emit('delete'),
                },
            ];
        },
    },
});
