import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, createElementVNode as _createElementVNode, withCtx as _withCtx, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4c197f75"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "loader-container flex flex-col items-center content-center justify-around h-full"
};
const _hoisted_2 = { class: "actions" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_or_notification = _resolveComponent("or-notification");
    const _component_or_loader = _resolveComponent("or-loader");
    const _component_contacts_columns_form = _resolveComponent("contacts-columns-form");
    const _component_or_button = _resolveComponent("or-button");
    const _component_or_modal = _resolveComponent("or-modal");
    return (_openBlock(), _createBlock(_component_or_modal, {
        "title-text": _ctx.$t('contacts.exportModal.title'),
        "is-open": _ctx.isOpen,
        size: "small"
    }, _createSlots({
        default: _withCtx(() => [
            (_ctx.isError)
                ? (_openBlock(), _createBlock(_component_or_notification, {
                    key: 0,
                    type: "error",
                    content: _ctx.$t('contacts.exportModal.exportError')
                }, null, 8, ["content"]))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    (_ctx.isLoading)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                            _createVNode(_component_or_loader, {
                                class: "w-full",
                                variant: "linear",
                                title: _ctx.$t('contacts.exportModal.exporting'),
                                description: _ctx.$t('contacts.exportModal.preparingData')
                            }, null, 8, ["title", "description"])
                        ]))
                        : (_openBlock(), _createBlock(_component_contacts_columns_form, {
                            key: 1,
                            modelValue: _ctx.arrayOfColumns,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.arrayOfColumns) = $event)),
                            title: _ctx.$t('contacts.exportModal.fieldsToExport')
                        }, null, 8, ["modelValue", "title"]))
                ], 64))
        ]),
        _: 2
    }, [
        (!_ctx.isError)
            ? {
                name: "footer",
                fn: _withCtx(({ close }) => [
                    _createElementVNode("div", _hoisted_2, [
                        _createVNode(_component_or_button, {
                            type: "outline",
                            content: _ctx.$t('common.cancel'),
                            onClick: close
                        }, null, 8, ["content", "onClick"]),
                        (!_ctx.isLoading)
                            ? (_openBlock(), _createBlock(_component_or_button, {
                                key: 0,
                                "is-disabled": _ctx.fieldsToExport.length === 0,
                                content: _ctx.$t('contacts.exportModal.exportButton'),
                                onClick: _ctx.handleExport
                            }, null, 8, ["is-disabled", "content", "onClick"]))
                            : _createCommentVNode("", true)
                    ])
                ]),
                key: "0"
            }
            : undefined
    ]), 1032, ["title-text", "is-open"]));
}
