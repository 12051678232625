import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-29b55ff0"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "pagination-length-selector" };
const _hoisted_2 = { class: "pagination-label" };
const _hoisted_3 = {
    class: "pagination-trigger",
    "data-test": "pagination-trigger"
};
const _hoisted_4 = {
    class: "length-list",
    "data-test": "length-list"
};
const _hoisted_5 = ["onClick"];
const _hoisted_6 = {
    key: 0,
    class: "info"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_or_label = _resolveComponent("or-label");
    const _component_or_icon = _resolveComponent("or-icon");
    const _component_or_overflow_menu = _resolveComponent("or-overflow-menu");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_or_label, { class: "label" }, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.label), 1)
                ]),
                _: 1
            })
        ]),
        _createVNode(_component_or_overflow_menu, {
            ref: "overflowMenu",
            placement: "top"
        }, {
            trigger: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                    _createTextVNode(_toDisplayString(_ctx.limit) + " ", 1),
                    _createVNode(_component_or_icon, { icon: "arrow_drop_down" })
                ])
            ]),
            content: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.variants, (variant) => {
                        return (_openBlock(), _createElementBlock("div", {
                            key: variant,
                            class: "item",
                            onClick: ($event) => (_ctx.handleSelect(variant))
                        }, _toDisplayString(variant), 9, _hoisted_5));
                    }), 128))
                ])
            ]),
            _: 1
        }, 512),
        (_ctx.total)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t('contacts.pagination.info', {
                from: _ctx.offset + 1,
                to: Math.min(_ctx.offset + _ctx.limit, _ctx.total),
                of: _ctx.total
            })), 1))
            : _createCommentVNode("", true)
    ]));
}
