import getDefaultContactsTableStoredValue from '../utils/getDefaultContactsTableStoredValue';
import { useLocalStorage, createSharedComposable } from '@vueuse/core';
import _ from 'lodash';
function useContactsTableStoredValue(bookName, schema, withCompositeColumns = true) {
    const contactsTableLocalStorageKey = bookName + ' Contacts Table';
    const defaultContactsTableStoredValue = getDefaultContactsTableStoredValue(schema, withCompositeColumns);
    const contactsTableStoredValue = useLocalStorage(contactsTableLocalStorageKey, defaultContactsTableStoredValue);
    const defaultColumns = defaultContactsTableStoredValue.columns;
    contactsTableStoredValue.value.columns = _.pick(_.defaults(contactsTableStoredValue.value.columns, defaultColumns), _.keys(defaultColumns));
    return contactsTableStoredValue;
}
export default createSharedComposable(useContactsTableStoredValue);
