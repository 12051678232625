import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "actions flex flex-row justify-between gap-md md:justify-end" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_ContactFormV2 = _resolveComponent("ContactFormV2");
    const _component_or_button = _resolveComponent("or-button");
    const _component_or_conditional_sidebar_modal = _resolveComponent("or-conditional-sidebar-modal");
    return (_openBlock(), _createBlock(_component_or_conditional_sidebar_modal, {
        "data-test": "edit-contact-modal",
        "is-open": _ctx.isOpen,
        onClose: _cache[2] || (_cache[2] = ($event) => (_ctx.$emit('close')))
    }, _createSlots({
        footer: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_or_button, {
                    "data-test": "cancel-edit-contact-button",
                    class: "!grow md:!grow-0",
                    variant: "outlined",
                    onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('close')))
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('common.cancel')), 1)
                    ]),
                    _: 1
                }),
                _createVNode(_component_or_button, {
                    "data-test": "update-contact-button",
                    class: "!grow md:!grow-0",
                    loading: _ctx.isSubmitting,
                    disabled: !_ctx.isChanged,
                    onClick: _ctx.triggerSubmit
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('common.save')), 1)
                    ]),
                    _: 1
                }, 8, ["loading", "disabled", "onClick"])
            ])
        ]),
        default: _withCtx(() => {
            var _a;
            return [
                _createVNode(_component_ContactFormV2, {
                    ref: "contactForm",
                    "data-test": "edit-form-contact-modal",
                    "field-schemas": _ctx.fieldSchemas,
                    "default-value": (_a = _ctx.contact) === null || _a === void 0 ? void 0 : _a.fieldValues,
                    onSubmit: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('update', _ctx.contact.id, $event)))
                }, null, 8, ["field-schemas", "default-value"])
            ];
        }),
        _: 2
    }, [
        (_ctx.isMobile)
            ? {
                name: "header",
                fn: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('contacts.editModal.title')), 1)
                ]),
                key: "0"
            }
            : undefined
    ]), 1032, ["is-open"]));
}
