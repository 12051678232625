import { defineComponent, ref } from 'vue';
import { useDraggable } from '@vueuse/core';
import { ContactBooksOrderModes } from '@/constants';
import { OrButtonV2 as OrButton } from '@onereach/ui-components';
export default defineComponent({
    name: 'ContactsTableColumnHeaderCell',
    components: {
        OrButton,
    },
    props: {
        columnKey: {
            type: String,
            required: true,
        },
        value: {
            type: String,
            required: true,
        },
        width: {
            type: Number,
            required: true,
        },
        canBeResizedNow: {
            type: Boolean,
            required: false,
            default: () => true,
        },
        canBeOrdered: {
            type: Boolean,
            required: false,
            default: () => false,
        },
        orderMode: {
            type: String,
            required: false,
            default: () => null,
        },
    },
    emits: ['barDragStart', 'widthChange', 'barDragEnd', 'order'],
    setup(props, { emit }) {
        const resizeBarRef = ref(null);
        const minWidth = 17; // 16px padding + 1px border
        let startPositionX = 0;
        let isResizing = ref(false);
        useDraggable(resizeBarRef, {
            onStart: (position, event) => {
                emit('barDragStart', props.columnKey);
                document.body.style.cursor = 'col-resize';
                document.body.style.userSelect = 'none';
                startPositionX = event.x;
                isResizing.value = true;
            },
            onMove: (position => {
                const delta = position.x - startPositionX;
                const newWidth = props.width + delta;
                const finalWidth = newWidth > minWidth ? newWidth : minWidth;
                emit('widthChange', props.columnKey, finalWidth, delta);
                startPositionX = position.x;
            }),
            onEnd: () => {
                emit('barDragEnd', props.columnKey);
                document.body.style.cursor = '';
                document.body.style.userSelect = '';
                isResizing.value = false;
            },
        });
        return {
            resizeBarRef,
            isResizing,
        };
    },
    computed: {
        icon() {
            switch (this.orderMode) {
                case ContactBooksOrderModes.ASC:
                    return 'arrow_downward';
                case ContactBooksOrderModes.DESC:
                    return 'arrow_upward';
                default:
                    return '';
            }
        },
    },
    methods: {
        changeOrder() {
            switch (this.orderMode) {
                case ContactBooksOrderModes.ASC:
                    this.$emit('order', ContactBooksOrderModes.DESC);
                    break;
                case ContactBooksOrderModes.DESC:
                    this.$emit('order', ContactBooksOrderModes.ASC);
                    break;
                default:
                    this.$emit('order', ContactBooksOrderModes.DESC);
                    break;
            }
        },
    },
});
