import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-40aaafdc"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "columns-list" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_or_checkbox = _resolveComponent("or-checkbox");
    const _component_contact_books_migration_form_item = _resolveComponent("contact-books-migration-form-item");
    return (_openBlock(), _createElementBlock("form", {
        class: "migration-form",
        onSubmit: _cache[0] || (_cache[0] = _withModifiers(() => { }, ["prevent"]))
    }, [
        _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_or_checkbox, {
                "model-value": _ctx.mainCheckboxState,
                "with-state": "",
                "onUpdate:modelValue": _ctx.handleSelectAll
            }, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('contacts.tableSettingsModal.selectAll')), 1)
                ]),
                _: 1
            }, 8, ["model-value", "onUpdate:modelValue"]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columnsModel, (column, index) => {
                var _a, _b;
                return (_openBlock(), _createBlock(_component_contact_books_migration_form_item, {
                    key: column.key,
                    modelValue: column.value,
                    "onUpdate:modelValue": [($event) => ((column.value) = $event), ($event) => (_ctx.handleUpdate(index, $event))],
                    "column-key": column.key,
                    label: column.label,
                    "type-constraint": (_b = (_a = _ctx.schema) === null || _a === void 0 ? void 0 : _a[column.key]) === null || _b === void 0 ? void 0 : _b.typeOf,
                    "all-fields": _ctx.fieldsV2,
                    "unavailable-field-ids": _ctx.selectedFieldIds,
                    "form-items": _ctx.formItems,
                    "is-loading": _ctx.isLoading,
                    "validation-scope": _ctx.validationScope
                }, null, 8, ["modelValue", "onUpdate:modelValue", "column-key", "label", "type-constraint", "all-fields", "unavailable-field-ids", "form-items", "is-loading", "validation-scope"]));
            }), 128))
        ])
    ], 32));
}
