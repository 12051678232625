import { OrCheckbox } from '@onereach/ui-components';
import { useVModel } from '@vueuse/core';
import _ from 'lodash';
import { defineComponent } from 'vue';
import ContactBooksMigrationFormItem from './ContactBooksMigrationFormItem.vue';
export default defineComponent({
    name: 'ContactBooksMigrationForm',
    components: {
        OrCheckbox,
        ContactBooksMigrationFormItem,
    },
    props: {
        modelValue: {
            type: Array,
            required: false,
            default: () => [],
        },
        isLoading: {
            type: Boolean,
            required: false,
            default: false,
        },
        schema: {
            type: Object,
            required: false,
            default: () => null,
        },
        fieldsV2: {
            type: Array,
            required: false,
            default: () => [],
        },
        validationScope: {
            type: String,
            required: false,
            default: 'ContactBooksMigration',
        },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        // something is wrong with 'deep' in useVModel, so have to emit update manually
        const columnsModel = useVModel(props, 'modelValue', emit, { deep: true });
        return {
            columnsModel,
        };
    },
    data() {
        return {
            formItems: new Set(),
        };
    },
    computed: {
        mainCheckboxState() {
            if (this.isAllSelected)
                return { icon: 'done' };
            if (this.isAnySelected)
                return { icon: 'remove' };
            return { icon: '' };
        },
        isAnySelected() {
            return _.some(this.columnsModel, column => column.value !== null);
        },
        isAllSelected() {
            return _.every(this.columnsModel, column => column.value !== null);
        },
        selectedFieldIds() {
            return _.reject(_.map(_.values(this.columnsModel), column => column.value), _.isNil);
        },
    },
    methods: {
        handleUpdate(index, value) {
            this.columnsModel[index].value = value;
            this.$emit('update:modelValue', this.columnsModel);
        },
        handleSelectAll() {
            const value = !this.isAllSelected && !this.isAnySelected ? undefined : null;
            _.forEach(this.columnsModel, (column) => column.value = value);
            this.$emit('update:modelValue', this.columnsModel);
        },
        scrollToFirstInvalidItem() {
            for (const item of this.formItems)
                if (item.v$.$invalid) {
                    item.$el.scrollIntoView({ behavior: 'smooth' });
                    return;
                }
        },
    },
});
