import { OrSwitch, OrSelect } from '@onereach/ui-components';
import useVuelidate from '@vuelidate/core';
import { requiredIf } from '@vuelidate/validators';
import { useVModel } from '@vueuse/core';
import _ from 'lodash';
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'ContactsImportMergeSwitch',
    components: {
        OrSwitch,
        OrSelect,
    },
    props: {
        modelValue: {
            type: [String, null],
            required: false,
            default: undefined,
        },
        columns: {
            type: Array,
            required: false,
            default: () => [],
        },
        isLoading: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const localValue = useVModel(props, 'modelValue', emit);
        const v$ = useVuelidate({ $scope: 'ContactsImport' });
        return {
            localValue,
            v$,
        };
    },
    computed: {
        isMergingEnabled: {
            get() {
                return this.localValue !== null;
            },
            set(value) {
                this.localValue = value ? undefined : null;
            },
        },
        columnsOptions() {
            return _.map(this.columns, column => ({
                value: column,
                label: column,
            }));
        },
        isUnselected() {
            return this.localValue === null;
        },
    },
    validations() {
        return {
            localValue: {
                requiredIfSelected: requiredIf(this.isMergingEnabled),
            },
        };
    },
    methods: {
        searchColumnsOptions({ search }) {
            return _.filter(this.columnsOptions, option => option.value.toLowerCase().includes(search.toLowerCase()));
        },
    },
});
