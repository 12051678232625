import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-42c88852"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "actions" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_contact_books_add_form = _resolveComponent("contact-books-add-form");
    const _component_or_button = _resolveComponent("or-button");
    const _component_or_modal = _resolveComponent("or-modal");
    return (_openBlock(), _createBlock(_component_or_modal, {
        "title-text": _ctx.$t('contactBooks.addModal.title'),
        "is-open": _ctx.isOpen,
        size: "medium"
    }, {
        footer: _withCtx(({ close }) => [
            _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_or_button, {
                    type: "outline",
                    content: _ctx.$t('common.cancel'),
                    onClick: close
                }, null, 8, ["content", "onClick"]),
                _createVNode(_component_or_button, {
                    "is-loading": _ctx.isSubmitting,
                    content: _ctx.$t('common.create'),
                    onClick: _ctx.triggerSubmit
                }, null, 8, ["is-loading", "content", "onClick"])
            ])
        ]),
        default: _withCtx(() => [
            _createVNode(_component_contact_books_add_form, {
                ref: "addBookForm",
                "schema-presets": _ctx.schemaPresets,
                "group-mapping": _ctx.groupMapping,
                "required-fields": _ctx.requiredFields,
                "required-fields-properties": _ctx.requiredFieldsProperties,
                "init-triggers": _ctx.initTriggers,
                "default-schema": _ctx.defaultSchema,
                onSubmit: _ctx.handleSubmit
            }, null, 8, ["schema-presets", "group-mapping", "required-fields", "required-fields-properties", "init-triggers", "default-schema", "onSubmit"])
        ]),
        _: 1
    }, 8, ["title-text", "is-open"]));
}
