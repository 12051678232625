import { OrButtonV2 as OrButton, OrIcon } from '@onereach/ui-components';
import { defineComponent } from 'vue';
import { RouterLink } from 'vue-router';
import { ContactBooksOrderModes } from '@/constants';
import { getBookSharingState } from '@/utils';
import ContactBookOverflowMenu from './ContactBookOverflowMenu';
import ContactBooksStatus from './ContactBooksStatus.vue';
export default defineComponent({
    name: 'ContactBooksTable',
    components: {
        OrButton,
        OrIcon,
        ContactBookOverflowMenu,
        RouterLink,
        ContactBooksStatus,
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
        order: {
            type: String,
            required: true,
        },
    },
    emits: ['changeOrder', 'open', 'migrate', 'delete', 'share'],
    data() {
        return {
            ContactBooksOrderModes,
        };
    },
    methods: {
        getBookState(book) {
            return getBookSharingState(book);
        },
    },
});
