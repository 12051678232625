import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_or_modal = _resolveComponent("or-modal");
    const _component_or_sidebar = _resolveComponent("or-sidebar");
    const _component_or_teleport = _resolveComponent("or-teleport");
    return (_ctx.isMobile && _ctx.isOpen)
        ? (_openBlock(), _createBlock(_component_or_modal, _mergeProps({ key: 0 }, _ctx.$props, {
            ref: "rootRef",
            onOpen: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('open'))),
            onClose: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('close')))
        }), _createSlots({
            default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "default")
            ]),
            _: 2
        }, [
            (_ctx.$slots.header)
                ? {
                    name: "header",
                    fn: _withCtx(() => [
                        _renderSlot(_ctx.$slots, "header")
                    ]),
                    key: "0"
                }
                : undefined,
            (_ctx.$slots.footer)
                ? {
                    name: "footer",
                    fn: _withCtx(() => [
                        _renderSlot(_ctx.$slots, "footer")
                    ]),
                    key: "1"
                }
                : undefined
        ]), 1040))
        : (_ctx.isOpen)
            ? (_openBlock(), _createBlock(_component_or_teleport, {
                key: 1,
                to: "#page-content-container",
                disabled: !_ctx.isAbsolutePositioned
            }, {
                default: _withCtx(() => [
                    _createVNode(_component_or_sidebar, _mergeProps(_ctx.$props, {
                        ref: "rootRef",
                        class: _ctx.sidebarStyles,
                        onOpen: _cache[2] || (_cache[2] = ($event) => (_ctx.$emit('open'))),
                        onClose: _cache[3] || (_cache[3] = ($event) => (_ctx.$emit('close')))
                    }), _createSlots({
                        default: _withCtx(() => [
                            _renderSlot(_ctx.$slots, "default")
                        ]),
                        _: 2
                    }, [
                        (_ctx.$slots.header)
                            ? {
                                name: "header",
                                fn: _withCtx(() => [
                                    _renderSlot(_ctx.$slots, "header")
                                ]),
                                key: "0"
                            }
                            : undefined,
                        (_ctx.$slots.footer)
                            ? {
                                name: "footer",
                                fn: _withCtx(() => [
                                    _renderSlot(_ctx.$slots, "footer")
                                ]),
                                key: "1"
                            }
                            : undefined
                    ]), 1040, ["class"])
                ]),
                _: 3
            }, 8, ["disabled"]))
            : _createCommentVNode("", true);
}
