import { OrButtonV2 as OrButton, OrModal, OrLoaderV3 as OrLoader, OrInput } from '@onereach/ui-components';
import useVuelidate from '@vuelidate/core';
import { minLength, required } from '@vuelidate/validators';
import _ from 'lodash';
import { defineComponent, ref } from 'vue';
import { getCompositeColumnsMapping } from '@/utils';
import ContactBooksMigrationForm from '../ContactBooksMigrationForm';
export default defineComponent({
    name: 'ContactBooksMigrationModal',
    components: {
        OrModal,
        OrButton,
        OrLoader,
        OrInput,
        ContactBooksMigrationForm,
    },
    props: {
        isOpen: {
            type: Boolean,
            required: true,
        },
        isLoading: {
            type: Boolean,
            required: false,
            default: false,
        },
        isPreloading: {
            type: Boolean,
            required: false,
            default: false,
        },
        bookName: {
            type: String,
            required: false,
            default: '',
        },
        schema: {
            type: Object,
            required: false,
            default: () => null,
        },
        fieldsV2: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    emits: ['migrate'],
    setup() {
        const bookNameInputRef = ref();
        const migrationFormRef = ref();
        const v$ = useVuelidate({ $scope: 'ContactBooksMigration' });
        return {
            bookNameInputRef,
            migrationFormRef,
            v$,
        };
    },
    data(props) {
        return {
            newBookName: props.bookName,
            migrationMapping: _.mapValues(props.schema, () => undefined),
        };
    },
    computed: {
        arrayOfColumns: {
            get() {
                return _.orderBy(_.toPairs(this.schema), '[1].order').map(([key, column]) => {
                    var _a, _b, _c;
                    return ({
                        key,
                        label: (_c = (_a = column === null || column === void 0 ? void 0 : column.label) !== null && _a !== void 0 ? _a : (_b = getCompositeColumnsMapping()[key]) === null || _b === void 0 ? void 0 : _b.label) !== null && _c !== void 0 ? _c : key,
                        value: this.migrationMapping[key],
                    });
                });
            },
            set(newColumns) {
                this.migrationMapping = _.fromPairs(_.map(newColumns, column => [column.key, column.value]));
            },
        },
        cleanMigrationMapping() {
            return _.pickBy(this.migrationMapping, _.negate(_.isNil));
        },
        isNothingSelected() {
            return _.every(this.migrationMapping, field => field === null);
        },
    },
    validations() {
        return {
            newBookName: {
                required,
                minLengthValue: minLength(3),
            },
        };
    },
    watch: {
        bookName: function (newVal, oldVal) {
            if (this.newBookName === oldVal)
                this.newBookName = newVal;
        },
        schema: function (newVal) {
            this.migrationMapping = _.mapValues(newVal, (value, key) => this.migrationMapping[key]);
        },
    },
    methods: {
        onBookNameBlur() {
            this.newBookName = _.trim(this.newBookName);
            this.v$.newBookName.$touch();
        },
        async handleMigrate() {
            var _a, _b;
            this.v$.$touch();
            if (await this.v$.$validate()) {
                this.$emit('migrate', {
                    name: this.newBookName,
                    mapping: this.cleanMigrationMapping,
                });
            }
            else {
                if (this.v$.newBookName.$error) {
                    (_a = this.bookNameInputRef) === null || _a === void 0 ? void 0 : _a.$el.scrollIntoView({ behavior: 'smooth' });
                }
                else {
                    (_b = this.migrationFormRef) === null || _b === void 0 ? void 0 : _b.scrollToFirstInvalidItem();
                }
            }
        },
    },
});
