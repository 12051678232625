import { defineComponent } from 'vue';
import { OrButtonV2 as OrButton, OrModal, OrRadio } from '@onereach/ui-components';
import { ContactsViewField, ContactsViewModalProfileInfo } from '../ContactsView/ContactsViewField';
import { getCompositeColumnsMapping, getContactPropertiesToShow, convertSchemaToOrderedArray } from '@/utils';
import { CompositeColumnKeys, SchemaItemTypes } from '@/constants';
const alreadyShownKeys = getCompositeColumnsMapping()[CompositeColumnKeys.NAME].parts;
const unionTypes = [
    SchemaItemTypes.ARRAY_OF_OBJECTS,
    SchemaItemTypes.ARRAY_OF_STRINGS,
    SchemaItemTypes.ARRAY_OF_IDENTIFIERS,
];
export default defineComponent({
    name: 'ContactMergeModal',
    components: {
        OrModal,
        OrButton,
        OrRadio,
        ContactsViewModalProfileInfo,
        ContactsViewField,
    },
    props: {
        isOpen: {
            type: Boolean,
            required: true,
        },
        isMerging: {
            type: Boolean,
            required: true,
        },
        schema: {
            type: Object,
            required: false,
            default: () => null,
        },
        contacts: {
            type: Array,
            required: true,
        },
    },
    emits: ['merge'],
    setup() {
        return {
            unionTypes,
        };
    },
    data(props) {
        return {
            selected: props.contacts[0].contactId,
        };
    },
    computed: {
        selectedId() {
            var _a, _b;
            return (_b = (_a = this.selected) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : this.selected;
        },
        unselectedId() {
            return this.contacts[0].contactId === this.selectedId ?
                this.contacts[1].contactId :
                this.contacts[0].contactId;
        },
        orderedSchemaItems() {
            return convertSchemaToOrderedArray(this.schema)
                .filter(({ key }) => !alreadyShownKeys.includes(key) && this.propertiesToShow[key]);
        },
        propertiesToShow() {
            return getContactPropertiesToShow(this.contacts);
        },
    },
    methods: {
        selectContact(contactId) {
            this.selected = contactId;
        },
    },
});
