import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "flex flex-col gap-md" };
const _hoisted_2 = { class: "flex flex-col flex-row md:flex-row gap-md md:gap-xs items-stretch" };
const _hoisted_3 = { class: "typography-body-2-regular" };
const _hoisted_4 = { class: "pt-xl overflow-hidden" };
const _hoisted_5 = { class: "max-h-[320px] overflow-auto" };
const _hoisted_6 = { class: "max-h-[320px] overflow-auto" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_OrSwitch = _resolveComponent("OrSwitch");
    const _component_OrInput = _resolveComponent("OrInput");
    const _component_OrSelect = _resolveComponent("OrSelect");
    const _component_OrButton = _resolveComponent("OrButton");
    const _component_ActiveShareItemActions = _resolveComponent("ActiveShareItemActions");
    const _component_contact_share_item = _resolveComponent("contact-share-item");
    const _component_ArchivedShareItemActions = _resolveComponent("ArchivedShareItemActions");
    const _component_OrTabs = _resolveComponent("OrTabs");
    const _component_OrModal = _resolveComponent("OrModal");
    return (_openBlock(), _createBlock(_component_OrModal, {
        "is-open": _ctx.isOpen,
        size: "m"
    }, {
        header: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('contacts.shareModal.title')), 1)
        ]),
        default: _withCtx(() => {
            var _a, _b;
            return [
                _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_OrSwitch, {
                        modelValue: _ctx.shareWithCustomName,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.shareWithCustomName) = $event)),
                        label: _ctx.$t('contacts.shareModal.shareWithCustomName')
                    }, null, 8, ["modelValue", "label"]),
                    (_ctx.shareWithCustomName)
                        ? (_openBlock(), _createBlock(_component_OrInput, {
                            key: 0,
                            modelValue: _ctx.customBookName,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.customBookName) = $event)),
                            class: "grow",
                            disabled: _ctx.isLoading,
                            label: _ctx.$t('contacts.shareModal.BookName'),
                            placeholder: _ctx.$t('contacts.shareModal.EnterBookName'),
                            error: _ctx.v$.customBookName.$invalid && !_ctx.isLoading ? (_a = _ctx.v$.customBookName.$errors[0]) === null || _a === void 0 ? void 0 : _a.$message : null,
                            onBlur: _ctx.trimAccountId
                        }, null, 8, ["modelValue", "disabled", "label", "placeholder", "error", "onBlur"]))
                        : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_2, [
                        _createVNode(_component_OrInput, {
                            modelValue: _ctx.accountToInvite,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.accountToInvite) = $event)),
                            class: "w-full md:w-2/4",
                            disabled: _ctx.isLoading,
                            label: _ctx.$t('contacts.shareModal.accountId'),
                            placeholder: _ctx.$t('contacts.shareModal.EnterAccountId'),
                            error: _ctx.v$.accountToInvite.$invalid && !_ctx.isLoading ? (_b = _ctx.v$.accountToInvite.$errors[0]) === null || _b === void 0 ? void 0 : _b.$message : null,
                            onBlur: _ctx.trimAccountId
                        }, null, 8, ["modelValue", "disabled", "label", "placeholder", "error", "onBlur"]),
                        _createVNode(_component_OrSelect, {
                            modelValue: _ctx.selectedPermission,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((_ctx.selectedPermission) = $event)),
                            class: "w-full md:w-2/4",
                            options: _ctx.permissionItems,
                            disabled: _ctx.isLoading,
                            label: _ctx.$t('contacts.shareModal.permission'),
                            placeholder: _ctx.$t('contacts.shareModal.selectPermission')
                        }, {
                            optionTemplate: _withCtx(({ option }) => [
                                _createElementVNode("span", _hoisted_3, _toDisplayString(option.label), 1)
                            ]),
                            _: 1
                        }, 8, ["modelValue", "options", "disabled", "label", "placeholder"]),
                        _createVNode(_component_OrButton, {
                            class: "whitespace-nowrap w-full self-baseline md:w-auto md:mt-[22px]",
                            loading: _ctx.isLoading,
                            onClick: _ctx.sendInvitation
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('contacts.shareModal.sendInvite')), 1)
                            ]),
                            _: 1
                        }, 8, ["loading", "onClick"])
                    ])
                ]),
                _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_OrTabs, {
                        modelValue: _ctx.currentTab,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((_ctx.currentTab) = $event)),
                        "data-test": "sharing-modal-tabs",
                        items: _ctx.tabItems
                    }, {
                        active: _withCtx(() => [
                            _createElementVNode("div", _hoisted_5, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getActive, (sm) => {
                                    return (_openBlock(), _createBlock(_component_contact_share_item, {
                                        key: sm.target_account,
                                        "data-test": "contact-share-item",
                                        meta: sm,
                                        "is-loading": _ctx.isLoading
                                    }, {
                                        action: _withCtx(() => [
                                            _createVNode(_component_ActiveShareItemActions, {
                                                "is-loading": _ctx.isLoading,
                                                meta: sm,
                                                onDelete: _ctx.deleteInvitation,
                                                onPermissionUpdate: _ctx.permissionUpdate
                                            }, null, 8, ["is-loading", "meta", "onDelete", "onPermissionUpdate"])
                                        ]),
                                        _: 2
                                    }, 1032, ["meta", "is-loading"]));
                                }), 128))
                            ])
                        ]),
                        archived: _withCtx(() => [
                            _createElementVNode("div", _hoisted_6, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getArchived, (sm) => {
                                    return (_openBlock(), _createBlock(_component_contact_share_item, {
                                        key: sm.target_account,
                                        "data-test": "contact-share-item",
                                        meta: sm,
                                        "is-loading": _ctx.isLoading,
                                        "show-deleted-tag": !_ctx.isDesktop
                                    }, {
                                        action: _withCtx(() => [
                                            _createVNode(_component_ArchivedShareItemActions, {
                                                "is-loading": _ctx.isLoading,
                                                "show-deleted-tag": _ctx.isDesktop,
                                                meta: sm,
                                                onRestore: _ctx.restoreSharingMeta
                                            }, null, 8, ["is-loading", "show-deleted-tag", "meta", "onRestore"])
                                        ]),
                                        _: 2
                                    }, 1032, ["meta", "is-loading", "show-deleted-tag"]));
                                }), 128))
                            ])
                        ]),
                        _: 1
                    }, 8, ["modelValue", "items"])
                ])
            ];
        }),
        _: 1
    }, 8, ["is-open"]));
}
