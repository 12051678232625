import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-53dbb6c8"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    class: "merge-content",
    "data-test": "merge-content"
};
const _hoisted_2 = { class: "contact-row radio-row" };
const _hoisted_3 = ["onClick"];
const _hoisted_4 = { class: "contact-info" };
const _hoisted_5 = ["onClick"];
const _hoisted_6 = { class: "actions" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_or_radio = _resolveComponent("or-radio");
    const _component_contacts_view_field = _resolveComponent("contacts-view-field");
    const _component_or_button = _resolveComponent("or-button");
    const _component_or_modal = _resolveComponent("or-modal");
    return (_openBlock(), _createBlock(_component_or_modal, {
        "title-text": _ctx.$t('contacts.mergeModal.title'),
        "is-open": _ctx.isOpen,
        size: "medium"
    }, {
        footer: _withCtx(({ close }) => [
            _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_or_button, {
                    type: "outline",
                    content: _ctx.$t('common.cancel'),
                    "data-test": "cancel-merge-contacts-button",
                    onClick: close
                }, null, 8, ["content", "onClick"]),
                _createVNode(_component_or_button, {
                    "is-loading": _ctx.isMerging,
                    content: _ctx.$t('contacts.mergeModal.merge'),
                    "data-test": "merge-contacts-button",
                    onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('merge', _ctx.selectedId, _ctx.unselectedId)))
                }, null, 8, ["is-loading", "content"])
            ])
        ]),
        default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contacts, (contact) => {
                        return (_openBlock(), _createElementBlock("div", {
                            key: contact.id,
                            class: _normalizeClass(["contact-cell radio-cell", { selected: contact.id === _ctx.selectedId }]),
                            "data-test": "main-contact-radio-cell",
                            onClick: ($event) => (_ctx.selectContact(contact.id))
                        }, [
                            _createVNode(_component_or_radio, {
                                modelValue: _ctx.selected,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.selected) = $event)),
                                value: contact.id,
                                label: _ctx.$t('contacts.mergeModal.mainContact')
                            }, null, 8, ["modelValue", "value", "label"])
                        ], 10, _hoisted_3));
                    }), 128))
                ]),
                _createElementVNode("div", _hoisted_4, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fieldSchemasToShow, (field) => {
                        return (_openBlock(), _createElementBlock("div", {
                            key: field.id,
                            class: "contact-row"
                        }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contacts, (contact) => {
                                var _a;
                                return (_openBlock(), _createElementBlock("div", {
                                    key: contact.id,
                                    class: _normalizeClass(["contact-cell", {
                                            selected: contact.id === _ctx.selectedId,
                                            deleting: !_ctx.unionTypes.includes(field.type)
                                        }]),
                                    onClick: ($event) => (_ctx.selectContact(contact.id))
                                }, [
                                    _createVNode(_component_contacts_view_field, {
                                        "field-schema": field,
                                        data: (_a = contact.fieldValues[field.id]) === null || _a === void 0 ? void 0 : _a.value,
                                        "always-show": _ctx.propertiesToShow[field.id]
                                    }, null, 8, ["field-schema", "data", "always-show"])
                                ], 10, _hoisted_5));
                            }), 128))
                        ]));
                    }), 128))
                ])
            ])
        ]),
        _: 1
    }, 8, ["title-text", "is-open"]));
}
