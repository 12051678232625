import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-06a57433"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    class: "view-mode",
    "data-test": "view-mode-switcher"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_or_icon_button = _resolveComponent("or-icon-button");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_or_icon_button, {
            class: "mode-switcher-icon icon-grid",
            icon: "apps",
            "data-test": "view-switcher-blocks",
            "icon-color": _ctx.activeMode === _ctx.ContactBooksViewModes.GRID ? 'neutral-5' : 'neutral-3',
            onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('select', _ctx.ContactBooksViewModes.GRID)))
        }, null, 8, ["icon-color"]),
        _createVNode(_component_or_icon_button, {
            class: "mode-switcher-icon icon-rows",
            "data-test": "view-switcher-rows",
            icon: "table_rows",
            "icon-color": _ctx.activeMode === _ctx.ContactBooksViewModes.TABLE ? 'neutral-5' : 'neutral-3',
            onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('select', _ctx.ContactBooksViewModes.TABLE)))
        }, null, 8, ["icon-color"])
    ]));
}
