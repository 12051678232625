import { OrButtonV2 as OrButton, OrModal, OrInput, OrIcon, OrError, OrCode, } from '@onereach/ui-components';
import useVuelidate from '@vuelidate/core';
import { useToggle } from '@vueuse/core';
import csvtojson from 'csvtojson';
import _ from 'lodash';
import { defineComponent, ref } from 'vue';
import ContactBooksMigrationForm from '@/components/ContactBooks/ContactBooksMigrationForm';
import ContactsImportMergeSwitch from './ContactsImportMergeSwitch.vue';
export default defineComponent({
    name: 'ContactsImportModal',
    components: {
        OrModal,
        OrButton,
        OrInput,
        OrIcon,
        OrError,
        OrCode,
        ContactBooksMigrationForm,
        ContactsImportMergeSwitch,
    },
    props: {
        isOpen: {
            type: Boolean,
            required: true,
        },
        isLoading: {
            type: Boolean,
            required: false,
            default: false,
        },
        fields: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    emits: ['import'],
    setup() {
        const uploadInputRef = ref();
        const migrationFormRef = ref();
        const mergeSwitchRef = ref();
        const [isPage2Open, togglePage2Open] = useToggle(false);
        return {
            v$: useVuelidate({ $scope: 'ContactsImport' }),
            uploadInputRef,
            migrationFormRef,
            mergeSwitchRef,
            isPage2Open,
            togglePage2Open,
        };
    },
    data() {
        return {
            paste: '',
            parsingError: '',
            dataToImport: [],
            mappedColumnsArr: [],
            columnToMergeBy: undefined,
            fileName: '',
            uploadType: 'file',
            wrongFileFormat: false,
            dragging: false,
            pastedUrl: '',
            isFirstPageLoading: false,
            maxFileSizeExceeded: false,
        };
    },
    computed: {
        fileExtension() {
            var _a;
            return _.last((_a = this.fileName) === null || _a === void 0 ? void 0 : _a.split('.'));
        },
        goToPage2Disabled() {
            if (this.pastedUrl)
                return false;
            if (this.uploadType === 'file' && (!this.fileName || this.wrongFileFormat))
                return true;
            if (this.uploadType === 'json' && !this.paste)
                return true;
            return this.maxFileSizeExceeded;
        },
        inputColumns() {
            return _.map(this.mappedColumnsArr, column => column.key);
        },
        cleanColumnsMapping() {
            return _.fromPairs(_.map(_.filter(this.mappedColumnsArr, column => !_.isNil(column.value)), column => [column.key, column.value]));
        },
        mergedDataToImport() {
            if (!this.columnToMergeBy)
                return this.dataToImport;
            return _.values(_.mapValues(_.groupBy(this.dataToImport, data => data[this.columnToMergeBy]), array => _.reduce(array, (result, value) => {
                for (const prop in value) {
                    if (_.isEqual(result[prop], value[prop]))
                        continue;
                    else if (_.isNil(result[prop]))
                        result[prop] = value[prop];
                    else if (_.isArray(result[prop]))
                        result[prop].push(value[prop]);
                    else
                        result[prop] = [result[prop], value[prop]];
                }
                return result;
            }, {})));
        },
        cleanDataToImport() {
            return _.map(this.mergedDataToImport, data => _.pick(data, _.keys(this.cleanColumnsMapping)));
        },
        isImportDisabled() {
            return _.keys(this.cleanColumnsMapping).length === 0;
        },
        schemaIdToMachineName() {
            return this.fields.reduce((acc, { id, machine_name }) => ({
                ...acc,
                [id]: machine_name,
            }), {});
        },
    },
    watch: {
        isOpen(val) {
            if (val) {
                this.resetInputs();
                this.togglePage2Open(false);
                this.uploadType = 'file';
            }
        },
    },
    methods: {
        resetInputs(omit) {
            if (omit !== 'fileName')
                this.maxFileSizeExceeded = false;
            const keys = ['fileName', 'pastedUrl', 'paste', 'parsingError'].filter(x => x !== omit);
            for (const key of keys) {
                this[key] = '';
            }
        },
        setFileName() {
            var _a, _b, _c;
            this.resetInputs('fileName');
            this.wrongFileFormat =
                this.uploadType === 'file' &&
                    !!_.get(this.uploadInputRef, 'files[0]') &&
                    !/\.(csv|json)$/.test((_a = _.get(this, 'uploadInputRef.files[0].name')) !== null && _a !== void 0 ? _a : '');
            if (!this.uploadInputRef)
                return;
            this.fileName = (_b = _.get(this, 'uploadInputRef.files[0].name')) !== null && _b !== void 0 ? _b : '';
            this.maxFileSizeExceeded = ((_c = _.get(this, 'uploadInputRef.files[0].size')) !== null && _c !== void 0 ? _c : 0) / 1024 / 1024 > 50;
        },
        changeUploadType() {
            this.fileName = '';
            this.uploadType = this.uploadType === 'file' ? 'json' : 'file';
        },
        async parseData() {
            var _a, _b, _c;
            try {
                this.parsingError = '';
                this.isFirstPageLoading = true;
                let rows;
                if (this.paste) {
                    try {
                        rows = JSON.parse(this.paste);
                    }
                    catch (e) {
                        rows = await csvtojson({
                            checkType: true,
                            ignoreEmpty: true,
                        }).fromString(this.paste);
                    }
                }
                else {
                    let text, fileIsJson;
                    if (this.pastedUrl) {
                        const meta = await fetch(this.pastedUrl);
                        if (meta.status !== 200)
                            throw this.$t('contacts.importModal.fileNotFound');
                        text = await meta.text();
                        try {
                            JSON.parse(text);
                            fileIsJson = true;
                        }
                        catch (e) {
                            fileIsJson = false;
                        }
                    }
                    else {
                        if (!((_b = (_a = this.uploadInputRef) === null || _a === void 0 ? void 0 : _a.files) === null || _b === void 0 ? void 0 : _b[0]))
                            return;
                        fileIsJson = /\.json$/i.test((_c = this.uploadInputRef.files[0].name) !== null && _c !== void 0 ? _c : '');
                        const reader = new FileReader();
                        reader.readAsText(this.uploadInputRef.files[0]);
                        text = await new Promise(resolve => {
                            reader.onload = () => resolve(reader.result);
                        });
                    }
                    if (fileIsJson) {
                        rows = JSON.parse(text);
                    }
                    else {
                        rows = await csvtojson({
                            checkType: true,
                            ignoreEmpty: true,
                        }).fromString(text);
                    }
                }
                this.dataToImport = _.isArray(rows) ? rows : [rows];
                this.mappedColumnsArr = [];
                this.columnToMergeBy = undefined;
                const keys = [];
                for (const row of this.dataToImport) {
                    for (const key in row) {
                        if (!keys.includes(key))
                            keys.push(key);
                    }
                }
                if (!keys.length || !this.dataToImport.length)
                    throw this.$t('contacts.importModal.cannotImportEmpty');
                for (const key of keys.sort()) {
                    this.mappedColumnsArr.push({
                        key,
                        label: key,
                        value: undefined,
                    });
                }
                this.togglePage2Open(true);
            }
            catch (e) {
                this.parsingError = e;
            }
            finally {
                this.isFirstPageLoading = false;
            }
        },
        async importData() {
            var _a;
            this.v$.$touch();
            if (await this.v$.$validate()) {
                const fieldValues = _.map(this.cleanDataToImport, contact => _.map(contact, (value, key) => {
                    const schemaId = this.cleanColumnsMapping[key];
                    const machine_name = this.schemaIdToMachineName[schemaId];
                    return {
                        value,
                        schemaId,
                        machine_name,
                    };
                }));
                this.$emit('import', fieldValues);
            }
            else {
                if (this.mergeSwitchRef && this.mergeSwitchRef.v$.localValue.$error) {
                    this.mergeSwitchRef.$el.scrollIntoView({ behavior: 'smooth' });
                }
                else {
                    (_a = this.migrationFormRef) === null || _a === void 0 ? void 0 : _a.scrollToFirstInvalidItem();
                }
            }
        },
    },
});
