import { defineComponent } from 'vue';
import { SchemaItemTypes } from '@/constants';
import { hasOwnProperty } from '@/utils';
import ContactFormFieldString from './ContactFormFieldString.vue';
import ContactFormFieldInteger from './ContactFormFieldInteger.vue';
import ContactFormFieldDouble from './ContactFormFieldDouble.vue';
import ContactFormFieldArrayOfStrings from './ContactFormFieldArrayOfStrings.vue';
import ContactFormFieldArrayOfObjects from './ContactFormFieldArrayOfObjects.vue';
import ContactFormFieldArrayOfIdentifiers from './ContactFormFieldArrayOfIdentifiers.vue';
import ContactFormFieldIdentifierObject from './ContactFormFieldIdentifierObject.vue';
import ContactFormFieldMD from './ContactFormFieldMD.vue';
import ContactFormFieldDate from './ContactFormFieldDate.vue';
const FieldComponents = {
    [SchemaItemTypes.STRING]: ContactFormFieldString,
    [SchemaItemTypes.DATE]: ContactFormFieldDate,
    [SchemaItemTypes.DOUBLE]: ContactFormFieldDouble,
    [SchemaItemTypes.MD]: ContactFormFieldMD,
    [SchemaItemTypes.INTEGER]: ContactFormFieldInteger,
    [SchemaItemTypes.ARRAY_OF_STRINGS]: ContactFormFieldArrayOfStrings,
    [SchemaItemTypes.ARRAY_OF_OBJECTS]: ContactFormFieldArrayOfObjects,
    [SchemaItemTypes.ARRAY_OF_IDENTIFIERS]: ContactFormFieldArrayOfIdentifiers,
    [SchemaItemTypes.IDENTIFIER_OBJECT]: ContactFormFieldIdentifierObject,
};
export default defineComponent({
    name: 'ContactFormField',
    props: {
        fieldSchema: {
            type: Object,
            required: true,
        },
        data: {
            type: [Object, String, Number],
            required: false,
            default: () => undefined,
        },
    },
    emits: ['update'],
    setup() {
        return {
            FieldComponents,
        };
    },
    computed: {
        isComponentImplemented() {
            return this.fieldSchema.typeOf in FieldComponents;
        },
        component() {
            return hasOwnProperty(FieldComponents, this.fieldSchema.typeOf)
                ? FieldComponents[this.fieldSchema.typeOf]
                : null;
        },
        showTitle() {
            return ![
                SchemaItemTypes.STRING,
                SchemaItemTypes.DOUBLE,
                SchemaItemTypes.INTEGER,
                SchemaItemTypes.DATE,
            ].includes(this.fieldSchema.typeOf);
        },
    },
});
