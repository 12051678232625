import { defineComponent } from 'vue';
import { OrButtonV2 as OrButton } from '@onereach/ui-components';
export default defineComponent({
    name: 'ContactsDeleteButton',
    components: {
        OrButton,
    },
    props: {
        count: {
            type: Number,
            required: false,
            default: () => null,
        },
        deleteAll: {
            type: Boolean,
            required: false,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    emits: ['click'],
    computed: {
        label() {
            return this.deleteAll ?
                this.$t('contacts.deleteAll') :
                this.$t('contacts.delete', { count: this.count });
        },
    },
});
