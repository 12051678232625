import { OrButtonV2 as OrButton, OrModal, OrLoaderV3 as OrLoader, OrNotification } from '@onereach/ui-components';
import _ from 'lodash';
import { defineComponent } from 'vue';
import ContactsColumnsForm from '../ContactsColumnsForm';
export default defineComponent({
    name: 'ContactExportModal',
    components: {
        OrModal,
        OrButton,
        OrLoader,
        OrNotification,
        ContactsColumnsForm,
    },
    props: {
        isOpen: {
            type: Boolean,
            required: true,
        },
        isLoading: {
            type: Boolean,
            required: false,
            default: false,
        },
        isError: {
            type: Boolean,
            required: false,
            default: false,
        },
        fieldSchemas: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    emits: ['export'],
    data(props) {
        return {
            fieldsToExport: _.map(props.fieldSchemas, field => field.id),
        };
    },
    computed: {
        arrayOfColumns: {
            get() {
                return _.map(this.fieldSchemas, field => {
                    var _a;
                    return ({
                        key: field.id,
                        label: (_a = field.label) !== null && _a !== void 0 ? _a : field.id,
                        value: this.fieldsToExport.includes(field.id),
                    });
                });
            },
            set(newColumns) {
                this.fieldsToExport = _.map(_.filter(newColumns, 'value'), 'key');
            },
        },
    },
    watch: {
        fieldSchemas: function (newVal, oldVal) {
            if (_.isEqual(this.fieldsToExport, _.map(oldVal, field => field.id)))
                _.map(newVal, field => field.id);
        },
    },
    methods: {
        handleExport() {
            this.$emit('export', this.fieldsToExport);
        },
    },
});
