import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-26d06622"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "or-select-input-value" };
const _hoisted_2 = { "data-test": "trigger-text" };
const _hoisted_3 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_or_icon = _resolveComponent("or-icon");
    const _component_or_select = _resolveComponent("or-select");
    return (_openBlock(), _createBlock(_component_or_select, _mergeProps(_ctx.$props, { options: _ctx.options }), {
        "input-value": _withCtx(({ value }) => [
            _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_or_icon, { icon: value }, null, 8, ["icon"]),
                _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.getTriggerIconText(value)), 1)
            ])
        ]),
        "content-option": _withCtx(({ option, isActive, onSelect }) => [
            _createElementVNode("div", {
                class: _normalizeClass(["or-select-option", { active: isActive }]),
                "data-test": "schema-editor-icon-select-option",
                onClick: onSelect
            }, [
                _createVNode(_component_or_icon, {
                    icon: option.value
                }, null, 8, ["icon"]),
                _createElementVNode("span", null, _toDisplayString(option.label), 1)
            ], 10, _hoisted_3)
        ]),
        _: 1
    }, 16, ["options"]));
}
