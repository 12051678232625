import { defineComponent, ref } from 'vue';
import { OrButtonV2 as OrButton, OrModal } from '@onereach/ui-components';
import ContactForm from '../ContactForm';
export default defineComponent({
    name: 'ContactAddModal',
    components: {
        OrModal,
        OrButton,
        ContactForm,
    },
    props: {
        isOpen: {
            type: Boolean,
            required: true,
        },
        isSubmitting: {
            type: Boolean,
            required: true,
        },
        schema: {
            type: Object,
            required: false,
            default: null,
        },
    },
    emits: ['add'],
    setup() {
        const contactForm = ref();
        return {
            contactForm,
        };
    },
    computed: {
        isChanged() {
            var _a, _b;
            return (_b = (_a = this.contactForm) === null || _a === void 0 ? void 0 : _a.isChanged) !== null && _b !== void 0 ? _b : false;
        },
    },
    methods: {
        triggerSubmit() {
            var _a;
            (_a = this.contactForm) === null || _a === void 0 ? void 0 : _a.submit();
        },
    },
});
