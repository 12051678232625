import { OrButtonV3 as OrButton, useResponsive } from '@onereach/ui-components';
import { defineComponent, ref } from 'vue';
import OrConditionalSidebarModal from '../../OrConditionalSidebarModal.vue';
import { ContactFormV2 } from '../ContactForm';
export default defineComponent({
    name: 'ContactAddModal',
    components: {
        OrConditionalSidebarModal,
        OrButton,
        ContactForm: ContactFormV2,
    },
    props: {
        isOpen: {
            type: Boolean,
            required: true,
        },
        isSubmitting: {
            type: Boolean,
            required: true,
        },
        fieldSchemas: {
            type: Array,
            required: true,
        },
    },
    emits: ['add', 'close'],
    setup() {
        const contactForm = ref();
        const { isMobile } = useResponsive();
        return {
            contactForm,
            isMobile,
        };
    },
    computed: {
        isChanged() {
            var _a, _b;
            return (_b = (_a = this.contactForm) === null || _a === void 0 ? void 0 : _a.isChanged) !== null && _b !== void 0 ? _b : false;
        },
    },
    methods: {
        triggerSubmit() {
            var _a;
            (_a = this.contactForm) === null || _a === void 0 ? void 0 : _a.submit();
        },
    },
});
