import { defineComponent } from 'vue';
import { OrIcon, OrSelect } from '@onereach/ui-components';
import { getTriggerIcons } from '@/utils';
import _ from 'lodash';
export default defineComponent({
    name: 'ContactBooksSchemaEditorItemTriggersItemIconSelect',
    components: {
        OrIcon,
        OrSelect,
    },
    computed: {
        options() {
            return getTriggerIcons(this.$t).map(({ icon, text }) => ({
                label: text,
                value: icon,
            }));
        },
    },
    methods: {
        getTriggerIconText(icon) {
            var _a, _b;
            return (_b = (_a = _.find(this.options, { value: icon })) === null || _a === void 0 ? void 0 : _a.label) !== null && _b !== void 0 ? _b : '';
        },
    },
});
