import { OrConfirmV3 } from '@onereach/ui-components';
import { defineComponent, ref } from 'vue';
export default defineComponent({
    name: 'ContactBooksDeleteModal',
    components: {
        OrConfirmV3,
    },
    props: {
        isLoading: {
            type: Boolean,
            required: false,
            default: false,
        },
        isShared: {
            type: Boolean,
            required: false,
            default: false,
        },
        contactBookName: {
            type: String,
            required: false,
            default: () => null,
        },
        deleteWithContacts: {
            type: Boolean,
            required: false,
            default: () => false,
        },
    },
    emits: ['accept'],
    expose: ['confirm'],
    setup(props) {
        const confirm = ref(null);
        const tKey = props.isShared
            ? 'contactBooks.deleteModal.deleteSharedBook'
            : props.deleteWithContacts
                ? 'contactBooks.deleteModal.deleteBookWithContacts'
                : 'contactBooks.deleteModal.deleteBook';
        return {
            confirm,
            tKey,
        };
    },
});
