import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-83cc34ae"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "contact-form-field" };
const _hoisted_2 = {
    key: 0,
    class: "contact-form-field-title"
};
const _hoisted_3 = { class: "contact-form-field-content" };
const _hoisted_4 = { key: 1 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.showTitle)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.fieldSchema.label), 1))
            : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
            (_ctx.isComponentImplemented)
                ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component), {
                    key: 0,
                    "field-schema": _ctx.fieldSchema,
                    data: _ctx.data,
                    "onUpdate:data": _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('update', $event)))
                }, null, 40, ["field-schema", "data"]))
                : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.fieldSchema.typeOf), 1))
        ])
    ]));
}
