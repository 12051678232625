import { OrButtonV2 as OrButton, OrModal, OrLoaderV3 as OrLoader, OrNotification } from '@onereach/ui-components';
import _ from 'lodash';
import { defineComponent } from 'vue';
import { getCompositeColumnsMapping } from '@/utils';
import ContactsColumnsForm from '../ContactsColumnsForm';
export default defineComponent({
    name: 'ContactExportModal',
    components: {
        OrModal,
        OrButton,
        OrLoader,
        OrNotification,
        ContactsColumnsForm,
    },
    props: {
        isOpen: {
            type: Boolean,
            required: true,
        },
        isLoading: {
            type: Boolean,
            required: false,
            default: false,
        },
        isError: {
            type: Boolean,
            required: false,
            default: false,
        },
        schema: {
            type: Object,
            required: false,
            default: () => null,
        },
    },
    emits: ['export'],
    data(props) {
        return {
            fieldsToExport: _.keys(props.schema),
        };
    },
    computed: {
        arrayOfColumns: {
            get() {
                return _.orderBy(_.toPairs(this.schema), '[1].order').map(([key, column]) => {
                    var _a, _b, _c;
                    return ({
                        key,
                        label: (_c = (_a = column === null || column === void 0 ? void 0 : column.label) !== null && _a !== void 0 ? _a : (_b = getCompositeColumnsMapping()[key]) === null || _b === void 0 ? void 0 : _b.label) !== null && _c !== void 0 ? _c : key,
                        value: this.fieldsToExport.includes(key),
                    });
                });
            },
            set(newColumns) {
                this.fieldsToExport = _.map(_.filter(newColumns, 'value'), 'key');
            },
        },
        orderedFieldsToExport() {
            return _.orderBy(this.fieldsToExport, key => this.schema[key].order);
        },
    },
    watch: {
        schema: function (newVal, oldVal) {
            if (_.isEqual(this.fieldsToExport, _.keys(oldVal)))
                this.fieldsToExport = _.keys(newVal);
        },
    },
    methods: {
        handleExport() {
            this.$emit('export', this.orderedFieldsToExport);
        },
    },
});
