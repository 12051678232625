import { defineComponent, ref } from 'vue';
import { OrButtonV2 as OrButton, OrModal } from '@onereach/ui-components';
import ContactsFilterForm from './ContactsFilterForm';
import useVuelidate from '@vuelidate/core';
import { getDefaultNewFilter } from '@/utils';
export default defineComponent({
    name: 'ContactsFilterModal',
    components: {
        OrModal,
        OrButton,
        ContactsFilterForm,
    },
    props: {
        isOpen: {
            type: Boolean,
            required: true,
        },
        isLoading: {
            type: Boolean,
            required: false,
            default: false,
        },
        filter: {
            type: Object,
            required: false,
            default: () => getDefaultNewFilter(),
        },
        allFilters: {
            type: Array,
            required: false,
            default: () => [],
        },
        schema: {
            type: Object,
            required: false,
            default: () => null,
        },
    },
    emits: ['save'],
    setup() {
        return {
            v$: useVuelidate({ $scope: 'ContactsFilterEditor' }),
            filterForm: ref(),
        };
    },
    computed: {
        areButtonsDisabled() {
            var _a, _b;
            return !((_a = this.filterForm) === null || _a === void 0 ? void 0 : _a.isChanged) || ((_b = this.filterForm) === null || _b === void 0 ? void 0 : _b.getOutputFilter().conditions.length) === 0;
        },
    },
    methods: {
        async triggerSave() {
            var _a, _b;
            this.v$.$touch();
            if (await this.v$.$validate()) {
                this.$emit('save', (_a = this.filterForm) === null || _a === void 0 ? void 0 : _a.getOutputFilter());
            }
            else {
                (_b = this.filterForm) === null || _b === void 0 ? void 0 : _b.scrollToFirstInvalidItem();
            }
        },
    },
});
