import { ColumnTypes } from '@onereach/types-contacts-api';
import { OrButtonV2 as OrButton, OrModal, OrRadio } from '@onereach/ui-components';
import { defineComponent } from 'vue';
import { getContactPropertiesToShowV2 } from '@/utils';
import { ContactsViewFieldV2 } from '../ContactsView/ContactsViewField';
const unionTypes = [
    ColumnTypes['array of objects'],
    ColumnTypes['array of strings'],
    ColumnTypes['array of identifiers'],
];
export default defineComponent({
    name: 'ContactMergeModal',
    components: {
        OrModal,
        OrButton,
        OrRadio,
        ContactsViewField: ContactsViewFieldV2,
    },
    props: {
        isOpen: {
            type: Boolean,
            required: true,
        },
        isMerging: {
            type: Boolean,
            required: true,
        },
        fieldSchemas: {
            type: Array,
            required: false,
            default: () => [],
        },
        contacts: {
            type: Array,
            required: true,
        },
    },
    emits: ['merge'],
    setup() {
        return {
            unionTypes,
        };
    },
    data(props) {
        return {
            selected: props.contacts[0].id,
        };
    },
    computed: {
        selectedId() {
            var _a, _b;
            return (_b = (_a = this.selected) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : this.selected;
        },
        unselectedId() {
            return this.contacts[0].id === this.selectedId ?
                this.contacts[1].id :
                this.contacts[0].id;
        },
        fieldSchemasToShow() {
            return this.fieldSchemas.filter(({ id }) => this.propertiesToShow[id]);
        },
        propertiesToShow() {
            return getContactPropertiesToShowV2(this.contacts);
        },
    },
    methods: {
        selectContact(contactId) {
            this.selected = contactId;
        },
    },
});
