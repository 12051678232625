import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4ea74d88"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "title"
};
const _hoisted_2 = { class: "columns-list" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_or_checkbox = _resolveComponent("or-checkbox");
    return (_openBlock(), _createElementBlock("form", {
        class: "columns-form",
        onSubmit: _cache[0] || (_cache[0] = _withModifiers(() => { }, ["prevent"]))
    }, [
        (_ctx.title)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.title), 1))
            : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_or_checkbox, {
                "model-value": _ctx.mainCheckboxState,
                "with-state": "",
                "onUpdate:modelValue": _ctx.handleSelectAll
            }, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('contacts.tableSettingsModal.selectAll')), 1)
                ]),
                _: 1
            }, 8, ["model-value", "onUpdate:modelValue"]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columnsModel, (column, index) => {
                return (_openBlock(), _createBlock(_component_or_checkbox, {
                    key: column.key,
                    "model-value": column.value,
                    "onUpdate:modelValue": ($event) => (_ctx.handleSelect(index))
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(column.label), 1)
                    ]),
                    _: 2
                }, 1032, ["model-value", "onUpdate:modelValue"]));
            }), 128))
        ])
    ], 32));
}
